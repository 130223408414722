program MnUtca;

uses
  Vcl.Forms,
  WEBLib.Forms,
  Fomenu in 'Source/Fomenu.pas'{*.html},
  Talalkozasok in 'Source/Talalkozasok/Talalkozasok.pas'{*.html},

  Belepes in 'Source/KiBelepes/Belepes.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TFomenuForm, FomenuForm);
  Application.Run;
end.
