unit Fomenu;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.WebCtrls, WEBLib.Actions, WEBLib.Menus, WEBLib.StdCtrls;

type
  TFomenuForm = class(TForm)
    divHost: THTMLDiv;
    wbBejelentkezes: TButton;
    wbKijelentkezes: TButton;
    wbTalalkozasok: TButton;
    wbUgyfeltortenet: TButton;
    procedure FomenuFormCreate(Sender: TObject);
    procedure wbBejelentkezesClick(Sender: TObject);
    procedure wbKijelentkezesClick(Sender: TObject);
    procedure wbTalalkozasokClick(Sender: TObject);
    procedure wbUgyfeltortenetClick(Sender: TObject);
  private
    { Private declarations }
    CurrentForm: TForm;
    CurrentFormName: String;
    CurrentFormIcon: String;

//    [async] procedure LoadForm(Form: String);  
    procedure AddNewClassName(var jsElement:TJSElement; const txt:string);
    procedure RemoveClassName(var jsElement:TJSElement; const txt:string);
    procedure ClearActiveMenuItem;
public
    { Public declarations }
    [async] procedure LoadForm(Form: String); async;  
  protected procedure LoadDFMValues; override; end;

var
  FomenuForm: TFomenuForm;

implementation

{$R *.dfm}

uses 
  web_elementclassname_helper,
  Belepes,
  Talalkozasok
;

procedure TFomenuForm.FomenuFormCreate(Sender: TObject);
begin
  asm
    window.document.dispatchEvent(new Event("DOMContentLoaded", {
      bubbles: true,
      cancelable: true
    }));
  end;
//  divHost.Top  := 300;
//  divHost.Height := 600;
  LoadForm('Belepes');
//  LoadForm('Talalkozasok');

end;

procedure TFomenuForm.LoadForm(Form: String);  
var  
  ElapsedTime: TDateTime;  
  
  procedure AfterCreate(AForm: TObject);  
  begin  
//    LogAction('Load Form: '+AForm.ClassName+' Loaded ('+IntToStr(MillisecondsBetween(Now, ElapsedTime))+'ms)');  
  end;  
  
begin  
  // Time this action  
  ElapsedTime := Now;  
  
  divHost.ElementHandle.style.setProperty('opacity','30','important');
//  asm await sleep(500); end;

  if Assigned(CurrentForm) then  
  begin  
//    LogAction('Removing Form: '+CurrentForm.ClassName);  
    CurrentForm.Close;  
    CurrentForm.Free;  
//    asm  
//      divHost.replaceChildren();  
//    end;  
  end;  
  
//  LogAction('Load Form: '+Form);  
  CurrentFormName := Form;  
  
  if Form = 'Talalkozasok' then  
  begin  
    CurrentForm := TTalalkozasokForm.CreateNew(divHost.ElementID, @AfterCreate);  
  end  
  else if Form = 'Belepes' then  
  begin  
    CurrentForm := TBelepesForm.CreateNew(divHost.ElementID, @AfterCreate);  
  end  
  else  
  begin  
    CurrentFormName := 'Invalid Form';  
//    LogAction('Form Not Found: '+Form);  
  
    if Form <> 'Clear' then 
    begin
      divHost.HTML.Text := 'ERROR: Form Not Found ('+Form+')';  
    end;  
  end;  

  divHost.ElementHandle.style.setProperty('opacity','100','important');
  
//  asm await sleep(500); end;
end;  

procedure TFomenuForm.AddNewClassName(var jsElement:TJSElement; const txt:string);
var
  idx:integer;
  className : String;
begin
  className := jsElement['class'];
  idx:=pos(txt, className);
  if (idx<1) then
  begin
    className:=className + ' ' + txt;
  end;
  jsElement['class'] := className;
end;

procedure TFomenuForm.RemoveClassName(var jsElement:TJSElement; const txt:string);
var
  idx, len:integer;
  wsL, wsR:string;
  className : String;

begin
  className := jsElement['class'];
  idx:=pos(txt, className);
  if (idx>0) then
  begin
    if (idx>1) then
    begin
      wsL:=LeftStr(className, idx-1);
    end
    else
    begin
      wsL:='';
    end;
    wsL:=TrimRight(wsL);

    len:=idx+Length(txt);
    wsR:=RightStr(className, Length(className)-len+1);
    wsR:=TrimLeft(wsR);

    className:='';
//    if (IsEmpty(wsL)) then
    if (wsL = '') then
    begin
//      if (not IsEmpty(wsR)) then
      if (wsR > '') then
      begin
        className:=wsR;
      end
    end
    else
    begin
//      if (IsEmpty(wsR)) then
      if (wsR = '') then
      begin
        className:=wsL;
      end
      else
      begin
        className:=wsL + ' ' + wsR;
      end;
    end;

  end;
  jsElement['class'] := className;
end;

procedure TFomenuForm.ClearActiveMenuItem;
var
  wel : TJSElement;

begin
  wel := document.getElementById('emFomenu');
  if (Assigned(wel)) then
  begin
    wel := wel.firstElementChild;
    while (Assigned(wel)) do
    begin
      if (wel['id'] > '') then
      begin
//        wel['class'] := 'sidebar-item';
        RemoveClassName(wel,  'active')
      end;
      wel := wel.nextElementSibling;
    end;  
  end;
end;

procedure TFomenuForm.wbUgyfeltortenetClick(Sender: TObject);
var
  wel : TJSElement;

begin
  ClearActiveMenuItem;
  wel := document.getElementById((Sender as TButton).ElementID);
  AddNewClassName(wel, 'active');

  //wel['class'] := 'sidebar-item active';
//  wel['class'] := 'sidebar-item  visually-hidden';
end;

procedure TFomenuForm.wbTalalkozasokClick(Sender: TObject);
var
  wel : TJSElement;

begin
  ClearActiveMenuItem;
  wel := document.getElementById((Sender as TButton).ElementID);
  wel['class'] := 'sidebar-item active';
end;

procedure TFomenuForm.wbBejelentkezesClick(Sender: TObject);
begin
  LoadForm('Belepes');
end;

procedure TFomenuForm.wbKijelentkezesClick(Sender: TObject);
begin
  //
end;

procedure TFomenuForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divHost := THTMLDiv.Create('divHost');
  wbBejelentkezes := TButton.Create('emBejelentkezes');
  wbKijelentkezes := TButton.Create('emKijelentkezes');
  wbTalalkozasok := TButton.Create('emTalalkozasok');
  wbUgyfeltortenet := TButton.Create('emUgyfeltortenet');

  divHost.BeforeLoadDFMValues;
  wbBejelentkezes.BeforeLoadDFMValues;
  wbKijelentkezes.BeforeLoadDFMValues;
  wbTalalkozasok.BeforeLoadDFMValues;
  wbUgyfeltortenet.BeforeLoadDFMValues;
  try
    Name := 'FomenuForm';
    CSSLibrary := cssBootstrap;
    Color := clWhite;
    ElementFont := efCSS;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 802;
    Left := 0;
    SetEvent(Self, 'OnCreate', 'FomenuFormCreate');
    TabOrder := 0;
    Top := 0;
    Width := 1002;
    divHost.SetParentComponent(Self);
    divHost.Name := 'divHost';
    divHost.ElementPosition := epRelative;
    divHost.Height := 96;
    divHost.Left := 451;
    divHost.Role := 'null';
    divHost.Top := 389;
    divHost.Width := 200;
    wbBejelentkezes.SetParentComponent(Self);
    wbBejelentkezes.Name := 'wbBejelentkezes';
    wbBejelentkezes.Caption := 'wbBejelentkezes';
    wbBejelentkezes.Color := clNone;
    wbBejelentkezes.ElementFont := efCSS;
    wbBejelentkezes.ElementPosition := epRelative;
    wbBejelentkezes.Font.Charset := ANSI_CHARSET;
    wbBejelentkezes.Font.Color := clBlack;
    wbBejelentkezes.Font.Height := -11;
    wbBejelentkezes.Font.Name := 'Arial';
    wbBejelentkezes.Font.Size := 8;
    wbBejelentkezes.Font.Style := [];
    wbBejelentkezes.Height := 25;
    wbBejelentkezes.Left := 135;
    SetEvent(wbBejelentkezes, Self, 'OnClick', 'wbBejelentkezesClick');
    wbBejelentkezes.Role := 'button';
    wbBejelentkezes.TabOrder := 0;
    wbBejelentkezes.Top := 392;
    wbBejelentkezes.Width := 100;
    wbKijelentkezes.SetParentComponent(Self);
    wbKijelentkezes.Name := 'wbKijelentkezes';
    wbKijelentkezes.Caption := 'wbKijelentkezes';
    wbKijelentkezes.Color := clNone;
    wbKijelentkezes.ElementFont := efCSS;
    wbKijelentkezes.ElementPosition := epRelative;
    wbKijelentkezes.Font.Charset := ANSI_CHARSET;
    wbKijelentkezes.Font.Color := clBlack;
    wbKijelentkezes.Font.Height := -11;
    wbKijelentkezes.Font.Name := 'Arial';
    wbKijelentkezes.Font.Size := 8;
    wbKijelentkezes.Font.Style := [];
    wbKijelentkezes.Height := 25;
    wbKijelentkezes.Left := 135;
    SetEvent(wbKijelentkezes, Self, 'OnClick', 'wbKijelentkezesClick');
    wbKijelentkezes.Role := 'button';
    wbKijelentkezes.TabOrder := 0;
    wbKijelentkezes.Top := 432;
    wbKijelentkezes.Width := 100;
    wbTalalkozasok.SetParentComponent(Self);
    wbTalalkozasok.Name := 'wbTalalkozasok';
    wbTalalkozasok.Caption := 'wbTalalkozasok';
    wbTalalkozasok.Color := clNone;
    wbTalalkozasok.ElementFont := efCSS;
    wbTalalkozasok.ElementPosition := epRelative;
    wbTalalkozasok.Font.Charset := ANSI_CHARSET;
    wbTalalkozasok.Font.Color := clBlack;
    wbTalalkozasok.Font.Height := -11;
    wbTalalkozasok.Font.Name := 'Arial';
    wbTalalkozasok.Font.Size := 8;
    wbTalalkozasok.Font.Style := [];
    wbTalalkozasok.Height := 25;
    wbTalalkozasok.Left := 135;
    SetEvent(wbTalalkozasok, Self, 'OnClick', 'wbTalalkozasokClick');
    wbTalalkozasok.Role := 'button';
    wbTalalkozasok.TabOrder := 0;
    wbTalalkozasok.Top := 472;
    wbTalalkozasok.Width := 100;
    wbUgyfeltortenet.SetParentComponent(Self);
    wbUgyfeltortenet.Name := 'wbUgyfeltortenet';
    wbUgyfeltortenet.Caption := 'wbUgyfeltortenet';
    wbUgyfeltortenet.Color := clNone;
    wbUgyfeltortenet.ElementFont := efCSS;
    wbUgyfeltortenet.ElementPosition := epRelative;
    wbUgyfeltortenet.Font.Charset := ANSI_CHARSET;
    wbUgyfeltortenet.Font.Color := clBlack;
    wbUgyfeltortenet.Font.Height := -11;
    wbUgyfeltortenet.Font.Name := 'Arial';
    wbUgyfeltortenet.Font.Size := 8;
    wbUgyfeltortenet.Font.Style := [];
    wbUgyfeltortenet.Height := 25;
    wbUgyfeltortenet.Left := 135;
    SetEvent(wbUgyfeltortenet, Self, 'OnClick', 'wbUgyfeltortenetClick');
    wbUgyfeltortenet.Role := 'button';
    wbUgyfeltortenet.TabOrder := 0;
    wbUgyfeltortenet.Top := 512;
    wbUgyfeltortenet.Width := 100;
  finally
    divHost.AfterLoadDFMValues;
    wbBejelentkezes.AfterLoadDFMValues;
    wbKijelentkezes.AfterLoadDFMValues;
    wbTalalkozasok.AfterLoadDFMValues;
    wbUgyfeltortenet.AfterLoadDFMValues;
  end;
end;

end.  