unit web_eo_common;


// HTML                    Element UI control class
//=================================================
// <LABEL>                 TWebLabel
// <INPUT type=”TEXT”>     TWebEdit
// <INPUT type=”NUMBER”>   TWebSpinEdit
// <INPUT type=”CHECK”>    TWebCheckBox
// <INPUT type=”RADIO”>    TWebRadioButton
// <INPUT type=”COLOR”>    TWebColorPicker
// <INPUT type=”DATE”>     TWebDateTimePicker
// <INPUT type=”RANGE”>    TWebTrackbar
// <SELECT>                TWebComboBox
// <TEXTAREA>              TWebMemo
// <PROGRESS>              TWebProgressbar
// <UL>                    TWebListControl
// <OL>                    TWebListControl
// <BUTTON>                TWebButton
// <DIV>                   TWebHTMLDiv
// <SPAN>                  TWebHTMLSpan
//------------------------------------------------
// <FORM>                                           TWebHTMLForm
// SPAN ID=”UniqueID”></SPAN>                       TWebPageControl
// <DIV ID=”UniqueID”></DIV>                        TWebPanel
// <DIV ID=”UniqueID”></DIV>                        TWebResponsiveGridPanel
// <SPAN ID=”UniqueID”></SPAN>                      TWebScrollBox
// <SELECT ID=”UniqueID”></SELECT>                  TWebLookupComboBox
// SELECT ID=”UniqueID”></SELECT>                   TWebListBox
// <SPAN><INPUT TYPE=”RADIO” ID=”UniqueID”></SPAN>  TWebRadioButton
// <DIV ID=”UniqueID”></DIV>                        TWebRichEdit
// <SPAN ID=”UniqueID”><TABLE></TABLE></SPAN>       TWebStringGrid



interface

uses
  System.Classes, System.SysUtils, WEBLib.Forms, WEBLib.Controls, Graphics, DB
  , JS    // JS tipusok, pl JSValue == variant
  ;

const
  WM_KEYDOWN : Cardinal =  $0100;


// POINTEREK NEM hasznalhatok a TMS-ben!
// enum KONSTANS SEM hasznalahato (a=0) pl type ABC=(a=0, b,c);
// variant tipus SEM hasznalhato, helyette JSValue. pelda: https://www.tmssoftware.com/site/blog.asp?post=803


// // igaz, hogy mindegyik elerheto, DE csak ezt hasznalni : HUNGARIAN_BOOLEAN_VALUE_PTR
// // tetszes szerint atcimezheto
// type   PHUNGARIAN_BOOLEAN_VALUE    = ^THUNGARIAN_BOOLEAN_VALUE;
//        THUNGARIAN_BOOLEAN_VALUE    = array[False..True] of string;
// const  HUNGARIAN_BOOLEAN_VALUE     : THUNGARIAN_BOOLEAN_VALUE = ('Nem', 'Igen');
// var    HUNGARIAN_BOOLEAN_VALUE_PTR : PHUNGARIAN_BOOLEAN_VALUE =@HUNGARIAN_BOOLEAN_VALUE;



type
  TETYPE =(EUNDEF, ESTRING, EDATETIME, EINTEGER, EDOUBLE, ELOGICAL, EMEMO, EBLOB, EBCD, EJSONDATE);

  // ertekek
  //  bit     3      2  1   0
  //    Visible Enable RO
  //          0      0  0   0 -  0 - nVnEnR
  //          0      0  1   0 -  2 - nVnE_R
  //          0      1  0   0 -  4 - nV_EnR
  //          0      1  1   0 -  6 - nV_E_R == NoVisible
  //          1      0  0   0 -  8   _VnEnR
  //          1      0  1   0 - 10   _VnE_R ==VisibleNoEnable
  //          1      1  0   0 - 12   _V_EnR ==VisibleEnableRW
  //          1      1  1   0 - 14   _V_E_R ==VisibleEnableRO
  TPERMISSION = (permDEFAULT, 
                 permw1,permw2,permw3,permw4,permw5,
                 permNoVisible,        //=6, 
                 permw7,permw8,permw9,
                 permVisibleNoEnable,  //=10, 
                 permw11,
                 permVisibleEnableRW,  //=12,
                 permw13,
                 permVisibleEnableRO   //=14
               );

  TOnValidateEvent =procedure(Sender: TObject) of object;

  ControlAttributes=record
                       name:string;
                       control :TControl;
                       orig_enabled :boolean;
                       orig_readonly:boolean;
                       orig_visible :boolean;
                       save_enabled :boolean;
                       save_readonly:boolean;
                       save_visible :boolean;
                    end;
  //TControlAttributes_arr = array of ControlAttributes;

  E_INTERFACE=interface(IInterface)
                 ['{745824AC-48AA-420A-B711-F17F0552CC56}']

                 procedure SetColorEif();  // atneveztem, mert utkozott a TControl.SetColor()-ral
                 procedure Clear();

                 function  getReadOnly:boolean; overload;
                 procedure setReadOnly(new_value:boolean); overload;

                 function  getEnabled:boolean;
                 procedure setEnabled(new_value:boolean);

                 function  getVisible:boolean;
                 procedure setVisible(new_value:boolean);

                 function  getIsModified:boolean;
                 procedure setIsModified(new_value:boolean);
                 property  IsModified  :boolean  read getIsModified  write setIsModified;

                 function  getHint_Error:string;
                 procedure setHint_Error(new_value:string);
                 property  Hint_Error      :string  read getHint_Error  write setHint_Error;

                 function  getHintSave:string;
                 procedure setHintSave(new_value:string);
                 property  HintSave      :string  read getHintSave  write setHintSave;

                 function  getIsValid:boolean;
                 procedure setIsValid(new_value:boolean);
                 property  IsValid         :boolean read getIsValid     write setIsValid;

                 function  getParentForm:TWebForm;
                 procedure setParentForm(new_value:TWebForm);
                 property  ParentForm   :TWebForm  read getParentForm  write setParentForm;

                //  function  getCustomNormalFg:TColor;
                //  procedure setCustomNormalFg(new_value:TColor);
                //  property  CustomNormalFg  :TColor  read getCustomNormalFg  write setCustomNormalFg;

                //  function  getCustomNormalBg:TColor;
                //  procedure setCustomNormalBg(new_value:TColor);
                //  property  CustomNormalBg  :TColor  read getCustomNormalBg  write setCustomNormalBg;

                 function  getHandleEsc:boolean;
                 procedure setHandleEsc(new_value:boolean);
                 property  HandleEsc  :boolean  read getHandleEsc  write setHandleEsc;

                 function  getStopWhenError:boolean;
                 procedure setStopWhenError(new_value:boolean);
                 property  StopWhenError  :boolean  read getStopWhenError  write setStopWhenError;

                 function  getLookIsModified:boolean;
                 procedure setLookIsModified(new_value:boolean);
                 property  LookIsModified :boolean  read getLookIsModified  write setLookIsModified;

                 function  getPermission:Integer;
                 procedure setPermission(new_value:Integer);
                 property  Permission:Integer  read getPermission write setPermission;

                 function  getObjectID:Integer;
                 procedure setObjectID(new_value:Integer);
                 property  ObjectID:Integer  read getObjectID write setObjectID;
                 //
                 function  getControlAttributes():ControlAttributes;
                 procedure setControlAttributes(new_value:ControlAttributes);
              end;

      E_OBJECT = interface(IInterface)
                 ['{745824AC-48AA-420A-B734-F17F0552CC56}']
      end;
  
      E_HOLDER = interface(IInterface)
                 ['{745824AC-4844-420A-B734-F17F0552CC56}']
      end;


// elsosorban listview, de kesobb a teljes rendszerben kene hasznalni
   ET_DataTypes = (
                    ET_Unknow,
                    ET_String,
                    ET_DateTime,
                    ET_Numeric,
                    ET_Logical,
                    ET_Memo,
                    ET_Blob,
                    ET_Bcd
                 );
//
   //function Get_ET_DataTypes(value:JSValue):ET_DataTypes; overload;
   function Get_ET_DataTypes(fld:TField):ET_DataTypes;    overload;






implementation

// function Get_ET_DataTypes(value:JSValue):ET_DataTypes; overload;
// begin
//    case value.VType of
//       vtInteger:       Result := ET_Numeric;
//       vtBoolean:       Result := ET_Logical;
//       vtChar:          Result := ET_String;
//       vtExtended:      Result := ET_Numeric;
//       vtString:        Result := ET_String;
//       vtPChar:         Result := ET_String;
//       vtObject:        Result := ET_Unknow;
//       vtClass:         Result := ET_Unknow;
//       vtAnsiString:    Result := ET_String;
//       vtUnicodeString: Result := ET_String;
//       vtCurrency:      Result := ET_Numeric;
//       vtVariant:       Result := ET_Numeric;
//       vtInt64:         Result := ET_Numeric;
//       else             Result := ET_Unknow;
//    end;
// end;


// sima komment "//" volt az eredetiben
// "//.." - nincs a TMS WEB-ben
function Get_ET_DataTypes(fld:TField):ET_DataTypes; overload;
begin
    case fld.DataType of
      ftWideString:    Result:=ET_String;
      ftFixedChar:     Result:=ET_String;
      //..ftGuid:          Result:=ET_String;
      ftString:        Result:=ET_String;
      //..ftSmallint:      Result:=ET_Numeric;
      ftInteger:       Result:=ET_Numeric;
      //..ftWord:          Result:=ET_Numeric;
      ftLargeint:      Result:=ET_Numeric;
      ftBoolean:       Result:=ET_Logical;
      ftFloat:         Result:=ET_Numeric;
      //..ftCurrency:      Result:=ET_Numeric;
      //ftBCD:           Result:=ET_Numeric;
      ftDate:          Result:=ET_DateTime;
      ftTime:          Result:=ET_DateTime;
      ftDateTime:      Result:=ET_DateTime;
      //..ftTimeStamp:     Result:=ET_DateTime;
      else             Result := ET_Unknow;
    end;
end;



end.

