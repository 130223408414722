unit Belepes;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, web_eo_string, web_eo_button;

type
  TBelepesForm = class(TForm)
    weJelszo: TWE_STRING;
    wbBelepes: TWE_BUTTON;
    WebEdit1: TEdit;
    procedure wbBelepesClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure Form1Show(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  BelepesForm: TBelepesForm;

implementation

{$R *.dfm}

uses
  Fomenu;

procedure TBelepesForm.Form1Show(Sender: TObject);
begin
//  weFelhasznalo.IsValid := False;
end;

procedure TBelepesForm.WebButton1Click(Sender: TObject);
var
  ws : String;
begin
  ws := '123';
WebEdit1.Text := 'gggggg';
  weJelszo.Text := 'aaaa';
end;

procedure TBelepesForm.wbBelepesClick(Sender: TObject);
begin
//  weJelszo.IsValid := False;
WebEdit1.Text := 'xxxx';
  weJelszo.Text := 'bbbb';
  FomenuForm.LoadForm('Talalkozasok');
end;

procedure TBelepesForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  weJelszo := TWE_STRING.Create('belepes_esJelszo');
  WebEdit1 := TEdit.Create('belepes_esFelhasznalo');
  wbBelepes := TWE_BUTTON.Create('belepes_ebBelepes');

  weJelszo.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  wbBelepes.BeforeLoadDFMValues;
  try
    Name := 'BelepesForm';
    Color := clWhite;
    ElementFont := efCSS;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 482;
    Left := 0;
    SetEvent(Self, 'OnShow', 'Form1Show');
    TabOrder := 0;
    Top := 0;
    Width := 754;
    weJelszo.SetParentComponent(Self);
    weJelszo.Name := 'weJelszo';
    weJelszo.Alignment := taLeftJustify;
    weJelszo.Color := clWindow;
    weJelszo.ElementClassName := ' color_normalfg color_normalbg';
    weJelszo.ElementFont := efCSS;
    weJelszo.ElementPosition := epRelative;
    weJelszo.Enabled := True;
    weJelszo.Font.Charset := ANSI_CHARSET;
    weJelszo.Font.Color := clBlack;
    weJelszo.Font.Height := -11;
    weJelszo.Font.Name := 'Arial';
    weJelszo.Font.Size := 8;
    weJelszo.Font.Style := [];
    weJelszo.HandleEsc := True;
    weJelszo.Height := 25;
    weJelszo.HideSelection := False;
    weJelszo.IsValid := True;
    weJelszo.Left := 0;
    weJelszo.LookIsModified := True;
    weJelszo.MaxLength := 0;
    weJelszo.ObjectID := 0;
    weJelszo.Permission := 0;
    weJelszo.ReadOnly := False;
    weJelszo.ShowFocus := True;
    weJelszo.StopWhenError := False;
    weJelszo.TabOrder := 0;
    weJelszo.Top := 0;
    weJelszo.Visible := True;
    weJelszo.Width := 100;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Alignment := taLeftJustify;
    WebEdit1.Color := clWindow;
    WebEdit1.ElementFont := efCSS;
    WebEdit1.ElementPosition := epRelative;
    WebEdit1.Font.Charset := ANSI_CHARSET;
    WebEdit1.Font.Color := clBlack;
    WebEdit1.Font.Height := -11;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Size := 8;
    WebEdit1.Font.Style := [];
    WebEdit1.Height := 25;
    WebEdit1.HideSelection := False;
    WebEdit1.Left := 0;
    WebEdit1.ShowFocus := True;
    WebEdit1.TabOrder := 0;
    WebEdit1.Top := 0;
    WebEdit1.Width := 100;
    wbBelepes.SetParentComponent(Self);
    wbBelepes.Name := 'wbBelepes';
    wbBelepes.Caption := 'wbBelepes';
    wbBelepes.Color := clNone;
    wbBelepes.ElementFont := efCSS;
    wbBelepes.ElementPosition := epRelative;
    wbBelepes.Enabled := True;
    wbBelepes.Font.Charset := ANSI_CHARSET;
    wbBelepes.Font.Color := clBlack;
    wbBelepes.Font.Height := -11;
    wbBelepes.Font.Name := 'Arial';
    wbBelepes.Font.Size := 8;
    wbBelepes.Font.Style := [];
    wbBelepes.HandleEsc := True;
    wbBelepes.Height := 25;
    wbBelepes.IsValid := True;
    wbBelepes.Left := 360;
    wbBelepes.LookIsModified := True;
    wbBelepes.ObjectID := 0;
    SetEvent(wbBelepes, Self, 'OnClick', 'wbBelepesClick');
    wbBelepes.Permission := 0;
    wbBelepes.ReadOnly := False;
    wbBelepes.Role := 'button';
    wbBelepes.StopWhenError := False;
    wbBelepes.TabOrder := 0;
    wbBelepes.Top := 176;
    wbBelepes.Visible := True;
    wbBelepes.Width := 100;
  finally
    weJelszo.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    wbBelepes.AfterLoadDFMValues;
  end;
end;

end.                        