unit web_elementclassname_helper;

{$mode ObjFPC}{$H+}

interface


    procedure AddNewClassName(var className:string; const txt:string);
    procedure RemoveClassName(var className:string; const txt:string);


implementation

uses
  Classes, SysUtils,
  web_es_string
  ;



procedure AddNewClassName(var className:string; const txt:string);
var
  idx:integer;
begin
  idx:=pos(txt, className);
  if (idx<1) then
  begin
    className:=className + ' ' + txt;
  end;
end;


procedure RemoveClassName(var className:string; const txt:string);
var
  idx, len:integer;
  wsL, wsR:string;
begin
  idx:=pos(txt, className);
  if (idx>0) then
  begin
    if (idx>1) then
    begin
      wsL:=LeftStr(className, idx-1);
    end
    else
    begin
      wsL:='';
    end;
    wsL:=TrimRight(wsL);

    len:=idx+Length(txt);
    wsR:=RightStr(className, Length(className)-len+1);
    wsR:=TrimLeft(wsR);

    className:='';
    if (IsEmpty(wsL)) then
    begin
      if (not IsEmpty(wsR)) then
      begin
        className:=wsR;
      end
    end
    else
    begin
      if (IsEmpty(wsR)) then
      begin
        className:=wsL;
      end
      else
      begin
        className:=wsL + ' ' + wsR;
      end;
    end;

  end;
end;



end.

