unit web_eform;

interface
uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, WEBLib.ComCtrls,
  generics.collections,
  web_eo_common, web_elementclassname_helper
  ;


const
  // ezen konstansok tartalmanak jelen KELL lennie a program .css file-ban, hogy mukodjon
  // itt megadom az "ajanlott" erteket - ettol el lehet terni
  COLOR_NORMALFG_CSS : string =  'color_normalfg';      // normal eloter szine
  COLOR_NORMALBG_CSS : string =  'color_normalbg';      // normal hatter szine
  COLOR_ERRORFG_CSS  : string =  'color_errorfg';       // hibas mezo eloter szine
  COLOR_ERRORBG_CSS  : string =  'color_errorbg';       // hibas mezo hatter szine
  COLOR_FOCUSFG_CSS  : string =  'color_focusfg';       // amikor fokuszt kap a mezo, eloter szine
  COLOR_FOCUSBG_CSS  : string =  'color_focusbg';       // amikor fokuszt kap a mezo, hatter szine
  //
  COLOR_NORMALFG_DATETIME_CSS : string =  'color_normalfg_datetime';      // normal eloter szine
  COLOR_NORMALBG_DATETIME_CSS : string =  'color_normalbg_datetime';      // normal hatter szine
  COLOR_ERRORFG_DATETIME_CSS  : string =  'color_errorfg_datetime';       // hibas mezo eloter szine
  COLOR_ERRORBG_DATETIME_CSS  : string =  'color_errorbg_datetime';       // hibas mezo hatter szine
  COLOR_FOCUSFG_DATETIME_CSS  : string =  'color_focusfg_datetime';       // amikor fokuszt kap a mezo, eloter szine
  COLOR_FOCUSBG_DATETIME_CSS  : string =  'color_focusbg_datetime';       // amikor fokuszt kap a mezo, hatter szine
  //
  NUMERIC_ALIGN_CSS  : string =  'numeric_align';       // jobbra igazitja az inputot


type
    // E_FORM_INTERFACE adat resze
    TE_FORM_EXTENSION_DATA = class (TObject)
    public
      IsValid           :Boolean;
      IsModified        :boolean;
      LookIsModified    :boolean;
      ReadOnlyProp      :boolean;
      NonValidObjectName:string;
    end;

    // a muveletekhez szukseges seged adatok
    TE_FORM_WORK_DATA = class(TE_FORM_EXTENSION_DATA)
    public
      //CtrlArr_num           : integer;
      //CtrlArr               : array of ControlAttributes;

      IsLoaded           : boolean;
      LastKey            : Word;
      LastShift          : TShiftState;
      EnabledFocusColor  : boolean;     // TRUE, ha fokuszra mas szine lesz

      Color_NormalFg       : string;          // normal eloter szine
      Color_NormalBg       : string;          // normal hatter szine
      Color_ErrorFg        : string;          // hibas mezo eloter szine
      Color_ErrorBg        : string;          // hibas mezo hatter szine
      Color_FocusFg        : string;          // amikor fokuszt kap a mezo, eloter szine
      Color_FocusBg        : string;          // amikor fokuszt kap a mezo, hatter szine

      Status               : integer;         // form allapota P_FELVITEL, P_MODOSIT, P_TORLES, P_LEKERDEZ
    end;

    // egy form adatai, hogy osszerendelheto legyen a TE_FORM_EXTENSION_DATA-val
    TE_FORM_INFO = class(TE_FORM_WORK_DATA)
    public
      FormName:string;
      Form:TWebForm;

      constructor Create(); 
      destructor  Destroy(); override;
    end;

    // list, ami tartalmazza a kiegeszito form adatokat
    TE_FORM_INFO_LIST =  TList<TE_FORM_INFO>;




      TE_FORM_EXTENSION = class helper for TWebForm
      private
         procedure Fill_FORM_INFO(formInfo:TE_FORM_INFO);

      public
         procedure RegisterForm();
         procedure UnRegisterForm();
         function  GetExtensionInfo() : TE_FORM_INFO;

         procedure SaveControlsState();
         procedure RestoreControlsState();
         procedure RestoreOriginalControlsState();

         procedure SetNormalColor();
         procedure SetErrorColor();
         procedure Validate();
         procedure Clear();

         procedure SetEnable(new_value:boolean);
         procedure SetReadOnly(new_value:boolean);
         procedure SetVisible(new_value:boolean);



         // TE_FORM_INFO adattartalmanak elerese
         function  getIsValid() : boolean;
         procedure setIsValid(new_value:boolean);

         function  getIsModified() : boolean;
         procedure setIsModified(new_value:boolean);

         function  getLookIsModified() : boolean;
         procedure setLookIsModified(new_value:boolean);

         function  getReadOnlyProp() : boolean;
         procedure setReadOnlyProp(new_value:boolean);

         function  getNonValidObjectName() : string;
         procedure setNonValidObjectName(new_value:string);

         function  getLastKey() : Word;
         procedure setLastKey(new_value:Word);

         function  getLastShift() : TShiftState;
         procedure setLastShift(new_value:TShiftState);

         function  getEnabledFocusColor() : boolean;
         procedure setEnabledFocusColor(new_value:boolean);

         function  getFormName() : string;
         procedure setFormName(new_value:string);

         function  getForm() : TWebForm;
         procedure setForm(new_value:TWebForm);
         //
         function  getColor_NormalFg() : string;
         procedure setColor_NormalFg(new_value:string);

         function  getColor_NormalBg() : string;
         procedure setColor_NormalBg(new_value:string);

         function  getColor_ErrorFg() : string;
         procedure setColor_ErrorFg(new_value:string);

         function  getColor_ErrorBg() : string;
         procedure setColor_ErrorBg(new_value:string);

         function  getColor_FocusFg() : string;
         procedure setColor_FocusFg(new_value:string);

         function  getColor_FocusBg() : string;
         procedure setColor_FocusBg(new_value:string);

         function  getStatus() : integer;
         procedure setStatus(new_value:integer);
         //


         // vegig megy a form osszes objektuman es az E_INTERFACE-re meghivja a FormFunction-t
         // !!! PageControl-nak az osszes fulet vizsgalja !!!
         // ha activePage=True, akkor CSAK az AKTIV page-t vizsgalja
         procedure EnumerateEinterface(obj:TControl; formInfo:TE_FORM_INFO; activePage:Boolean = False);
         // ez fogja az osszes funkciot vegrehajtani
         procedure FormFunction(obj:E_INTERFACE; formInfo:TE_FORM_INFO);



         property IsValid             :boolean      read getIsValid            write setIsValid;
         property IsModified          :boolean      read getIsModified         write setIsModified;
         property LookIsModified      :boolean      read getLookIsModified     write setLookIsModified;
         property ReadOnlyProp        :boolean      read getReadOnlyProp       write setReadOnlyProp;
         property NonValidObjectName  :string       read getNonValidObjectName write setNonValidObjectName;
         property LastKey             :Word         read getLastKey            write setLastKey;
         property LastShift           :TShiftState  read getLastShift          write setLastShift;
         property EnabledFocusColor   :boolean      read getEnabledFocusColor  write setEnabledFocusColor;
         property FormName            :string       read getFormName           write setFormName;
         property Form                :TWebForm     read getForm               write setForm;
         //
         property Color_NormalFg      :string       read getColor_NormalFg     write setColor_NormalFg;
         property Color_NormalBg      :string       read getColor_NormalBg     write setColor_NormalBg;
         property Color_ErrorFg       :string       read getColor_ErrorFg      write setColor_ErrorFg;
         property Color_ErrorBg       :string       read getColor_ErrorBg      write setColor_ErrorBg;
         property Color_FocusFg       :string       read getColor_FocusFg      write setColor_FocusFg;
         property Color_FocusBg       :string       read getColor_FocusBg      write setColor_FocusBg;
         //
         property FormID              :integer      read getStatus              write setStatus;

      end;

      function getE_interface(ctrl:TControl) : E_INTERFACE;
      function IsEInterface(ctrl:TControl) : Boolean;
      function IsEObject(ctrl:TControl) : Boolean;
      function IsEHolder(ctrl:TControl) : Boolean;

      function GetParentFormFunc(control:TControl) : TWebForm;
      //
      function SetNormalColor(elmentClassName:string) : string;
      function SetErrorColor(elmentClassName:string) : string;
      function SetFocusColor(elmentClassName:string) : string;
      //
      function SetNormalColor_DatetTime(elmentClassName:string) : string;
      function SetErrorColor_DatetTime(elmentClassName:string) : string;
      function SetFocusColor_DatetTime(elmentClassName:string) : string;
      //

var   
  GlobalFormList : TList<TE_FORM_INFO>;




implementation

uses
  web_eo_iif;


type
  FORM_FUNC=(FORM_UNDEF, 
             FORM_INIT, 
             FORM_SAVECONTROLSTATE, FORM_RESTORECONTROLSTATE, FORM_RESTOREORIGINALCONTROLSTATE, 
             FORM_SETNORMALCOLOR, FORM_SETERRORCOLOR,
             FORM_VALIDATE, 
             FORM_CLEAR,
             FORM_SETENABLE, FORM_SETREADONLY, FORM_SETVISIBLE
            );

var
  formfunc: FORM_FUNC;
  gnewValue:Boolean;


function getE_interface(ctrl:TControl) : E_INTERFACE;
begin
   Result:=nil;
   if (ctrl is E_INTERFACE) then
   begin
      Result:=(ctrl as E_INTERFACE);
   end
end;

function IsEInterface(ctrl:TControl) : Boolean;
begin
   Result:=(ctrl is E_INTERFACE);
end;

function IsEObject(ctrl:TControl) : Boolean;
begin
   Result:=(ctrl is E_OBJECT);
end;

function IsEHolder(ctrl:TControl) : Boolean;
begin
   Result:=(ctrl is E_HOLDER);
end;




function GetParentFormFunc(control:TControl) : TWebForm;
var
  rc:TControl;
begin
  rc:=control;
  while (Assigned(rc.Parent)) do
  begin
     rc:=rc.Parent; 
  end;
  Result:=TWebForm(rc);   // TODO: nem biztos, hogy TWebForm a szulo
end;



function SetNormalColor(elmentClassName:string) : string;
begin
   RemoveClassName(elmentClassName, COLOR_ERRORFG_CSS);
   RemoveClassName(elmentClassName, COLOR_ERRORBG_CSS);
   RemoveClassName(elmentClassName, COLOR_FOCUSFG_CSS);
   RemoveClassName(elmentClassName, COLOR_FOCUSBG_CSS);
   AddNewClassName(elmentClassName, COLOR_NORMALFG_CSS);
   AddNewClassName(elmentClassName, COLOR_NORMALBG_CSS);
   Result:=elmentClassName;
end;

function SetErrorColor(elmentClassName:string) : string;
begin
   RemoveClassName(elmentClassName, COLOR_NORMALFG_CSS);
   RemoveClassName(elmentClassName, COLOR_NORMALBG_CSS);
   RemoveClassName(elmentClassName, COLOR_FOCUSFG_CSS);
   RemoveClassName(elmentClassName, COLOR_FOCUSBG_CSS);
   AddNewClassName(elmentClassName, COLOR_ERRORFG_CSS);
   AddNewClassName(elmentClassName, COLOR_ERRORBG_CSS);
   Result:=elmentClassName;
end;

function SetFocusColor(elmentClassName:string) : string;
begin
   RemoveClassName(elmentClassName, COLOR_NORMALFG_CSS);
   RemoveClassName(elmentClassName, COLOR_NORMALBG_CSS);
   RemoveClassName(elmentClassName, COLOR_ERRORFG_CSS);
   RemoveClassName(elmentClassName, COLOR_ERRORBG_CSS);
   AddNewClassName(elmentClassName, COLOR_FOCUSFG_CSS);
   AddNewClassName(elmentClassName, COLOR_FOCUSBG_CSS);
   Result:=elmentClassName;
end;
//
function SetNormalColor_DatetTime(elmentClassName:string) : string;
begin
   RemoveClassName(elmentClassName, COLOR_ERRORFG_DATETIME_CSS);
   RemoveClassName(elmentClassName, COLOR_ERRORBG_DATETIME_CSS);
   RemoveClassName(elmentClassName, COLOR_FOCUSFG_DATETIME_CSS);
   RemoveClassName(elmentClassName, COLOR_FOCUSBG_DATETIME_CSS);
   AddNewClassName(elmentClassName, COLOR_NORMALFG_DATETIME_CSS);
   AddNewClassName(elmentClassName, COLOR_NORMALBG_DATETIME_CSS);
   Result:=elmentClassName;
end;

function SetErrorColor_DatetTime(elmentClassName:string) : string;
begin
   RemoveClassName(elmentClassName, COLOR_NORMALFG_DATETIME_CSS);
   RemoveClassName(elmentClassName, COLOR_NORMALBG_DATETIME_CSS);
   RemoveClassName(elmentClassName, COLOR_FOCUSFG_DATETIME_CSS);
   RemoveClassName(elmentClassName, COLOR_FOCUSBG_DATETIME_CSS);
   AddNewClassName(elmentClassName, COLOR_ERRORFG_DATETIME_CSS);
   AddNewClassName(elmentClassName, COLOR_ERRORBG_DATETIME_CSS);
   Result:=elmentClassName;
end;

function SetFocusColor_DatetTime(elmentClassName:string) : string;
begin
   RemoveClassName(elmentClassName, COLOR_NORMALFG_DATETIME_CSS);
   RemoveClassName(elmentClassName, COLOR_NORMALBG_DATETIME_CSS);
   RemoveClassName(elmentClassName, COLOR_ERRORFG_DATETIME_CSS);
   RemoveClassName(elmentClassName, COLOR_ERRORBG_DATETIME_CSS);
   AddNewClassName(elmentClassName, COLOR_FOCUSFG_DATETIME_CSS);
   AddNewClassName(elmentClassName, COLOR_FOCUSBG_DATETIME_CSS);
   Result:=elmentClassName;
end;
//







{%region TE_FORM_INFO}
constructor TE_FORM_INFO.Create();
begin
   inherited Create();
end;

destructor  TE_FORM_INFO.Destroy();
begin
   // this is the destroy function
   inherited;
end;
{%endregion TE_FORM_INFO}






procedure TE_FORM_EXTENSION.RegisterForm();
var
  info:TE_FORM_INFO;
  formInfo:TE_FORM_INFO;
  found:Boolean=false;
begin
  formfunc:=FORM_UNDEF;

  if (not (csDesigning in ComponentState)) then
  begin
    if (not Assigned(GlobalFormList)) then
    begin
      GlobalFormList:=TE_FORM_INFO_LIST.Create();
    end;
      
    for info in GlobalFormList do
    begin
      if (info.Form=Self) then
      begin
        found:=True;
      end;
    end;

    if (not found) then
    begin
      formInfo:=TE_FORM_INFO.Create();
      formInfo.FormName:=Self.Name;
      formInfo.Form:=Self;
      GlobalFormList.Add(formInfo);
      Fill_FORM_INFO(formInfo);
    end;
  end;
end;

procedure TE_FORM_EXTENSION.UnRegisterForm();
var
  formInfo:TE_FORM_INFO;
begin
    if (not (csDesigning in ComponentState)) then
    begin
    formInfo:=GetExtensionInfo();

    if (Assigned(formInfo)) then
    begin
      GlobalFormList.Remove(formInfo);
    end;
  end;
end;



function TE_FORM_EXTENSION.GetExtensionInfo() : TE_FORM_INFO;
var
  info:TE_FORM_INFO;
  formInfo:TE_FORM_INFO;
begin
  formInfo:=Nil;

  if (not (csDesigning in ComponentState)) then
  begin
    if (not Assigned(GlobalFormList)) then
    begin
      RegisterForm();
    end;

    for info in GlobalFormList do
    begin
      if (info.Form = Self) then
      begin
        formInfo:=info;
      end;
    end;
    Result:=formInfo;
  end;
end;


procedure TE_FORM_EXTENSION.Fill_FORM_INFO(formInfo:TE_FORM_INFO);
var
   i        :Integer;
   iface    :E_INTERFACE;
   form_ptr :TControl;
begin
  if (not Assigned(formInfo)) then
  begin
    raise Exception.Create('TE_FORM_EXTENSION.Fill_FORM_INFO: formInfo is Nil');
  end;

    if (Assigned(formInfo)) then
    begin
      formfunc:=FORM_INIT;
      EnumerateEinterface(Self, forminfo);

      with formInfo do
      begin
        IsValid           := False;
        IsModified        := False;
        LookIsModified    := False;
        ReadOnlyProp      := False;
        NonValidObjectName:= '';
        //
        IsLoaded           := False;
        LastKey            := 0;
        LastShift          := [];
        EnabledFocusColor  := true;     // TRUE, ha fokuszra mas szine lesz
        //
        Color_NormalFg       := COLOR_NORMALFG_CSS;          // normal eloter szine
        Color_NormalBg       := COLOR_NORMALBG_CSS;          // normal hatter szine
        Color_ErrorFg        := COLOR_ERRORFG_CSS;          // hibas mezo eloter szine
        Color_ErrorBg        := COLOR_ERRORBG_CSS;          // hibas mezo hatter szine
        Color_FocusFg        := COLOR_FOCUSFG_CSS;          // amikor fokuszt kap a mezo, eloter szine
        Color_FocusBg        := COLOR_FOCUSBG_CSS;          // amikor fokuszt kap a mezo, hatter szine
        
        Status               := 0;         // form allapota P_FELVITEL, P_MODOSIT, P_TORLES, P_LEKERDEZ
      end;
    end;
end;



procedure TE_FORM_EXTENSION.EnumerateEinterface(obj:TControl; formInfo:TE_FORM_INFO; activePage:Boolean = False);
var
   idx:Integer;
   sheetIdx:Integer;
   o:TControl;
   pgCtrl:TWebPageControl;
begin
  for idx:=0 to obj.ControlCount-1 do
  begin
    o:=obj.Controls[idx];
    if (o is TWebPageControl) then
    begin
      pgCtrl:=o as TWebPageControl;
      for sheetIdx:=0 to pgCtrl.ControlCount-1 do
      begin
         if ((activePage=True) and (sheetIdx=pgCtrl.ActivePageIndex)) then
         begin
            EnumerateEinterface(pgctrl.Controls[sheetIdx], formInfo, activePage);
         end;
         if (activePage=False) then
         begin
            EnumerateEinterface(pgctrl.Controls[sheetIdx], formInfo, activePage);
         end;
      end;
      //
    end
    else
    begin
      if (o.ControlCount>0) then
      begin
         EnumerateEinterface(o, formInfo, activePage);
      end
      else
      begin
         if (o is E_INTERFACE) then
         begin
           FormFunction(o as E_INTERFACE, formInfo);
         end;
      end;
    end;
  end;
end;

procedure TE_FORM_EXTENSION.FormFunction(obj:E_INTERFACE; formInfo:TE_FORM_INFO);
begin
   case formfunc of
      FORM_UNDEF:begin
          raise Exception.Create('TE_FORM_EXTENSION.FormFunction FORM_UNDEF!');
      end;
      FORM_INIT:begin
         with obj.getControlAttributes() do
         begin
           orig_enabled :=obj.getEnabled();
           orig_readonly:=obj.getReadOnly();
           orig_visible :=obj.getVisible();
           save_enabled :=orig_enabled;
           save_readonly:=orig_readonly;
           save_visible :=orig_visible;
         end;
      end;
      FORM_SAVECONTROLSTATE:begin
         with obj.getControlAttributes() do
         begin
           save_enabled :=obj.getEnabled();
           save_readonly:=obj.getReadOnly();
           save_visible :=obj.getVisible();
         end;  
      end;
      FORM_RESTORECONTROLSTATE:begin
         with obj.getControlAttributes() do
         begin
            obj.setEnabled(save_enabled);
            obj.setReadOnly(save_readonly);
            obj.setVisible(save_visible);
         end;
      end;
      FORM_RESTOREORIGINALCONTROLSTATE:begin
         with obj.getControlAttributes() do
         begin
            obj.setEnabled(orig_enabled);
            obj.setReadOnly(orig_readonly);
            obj.setVisible(orig_visible);
         end;
      end;
      FORM_SETNORMALCOLOR:begin
         obj.setIsValid(True);
      end;
      FORM_SETERRORCOLOR:begin
         obj.setIsValid(False);
      end;
      FORM_VALIDATE:begin
         formInfo.IsValid:=(obj.getIsValid() and formInfo.IsValid);
      end;
      FORM_CLEAR:begin
         obj.Clear();
      end;
      FORM_SETENABLE:begin
         obj.setEnabled(gnewValue);
      end;
      FORM_SETREADONLY:begin
         obj.setReadOnly(gnewValue);
      end;
      FORM_SETVISIBLE:begin
         obj.setVisible(gnewValue);
      end;
      else
      begin
          raise Exception.Create('TE_FORM_EXTENSION.FormFunction else!');
      end;
   end;
end;






// {%Region E_FORM_INTERFACE}
procedure TE_FORM_EXTENSION.SaveControlsState;
var
   formInfo   :TE_FORM_INFO;
begin
   formInfo:=GetExtensionInfo();
   if (not Assigned(formInfo)) then
   begin
      raise Exception.Create('TE_FORM_EXTENSION.SaveControlsState: formInfo is Nil');
   end;

   if (Assigned(formInfo)) then
   begin
      formfunc:=FORM_SAVECONTROLSTATE;
      EnumerateEinterface(Self, formInfo);
   end;
end;

procedure TE_FORM_EXTENSION.RestoreControlsState();
var
   formInfo   :TE_FORM_INFO;
begin
    formInfo:=GetExtensionInfo();
    if (not Assigned(formInfo)) then
    begin
      raise Exception.Create('TE_FORM_EXTENSION.RestoreControlsState: formInfo is Nil');
    end;

    if (Assigned(formInfo)) then
    begin
      formfunc:=FORM_RESTORECONTROLSTATE;
      EnumerateEinterface(Self, formInfo);
    end;
end;

procedure TE_FORM_EXTENSION.RestoreOriginalControlsState;
var
   formInfo   :TE_FORM_INFO;
begin
    formInfo:=GetExtensionInfo();
    if (not Assigned(formInfo)) then
    begin
      raise Exception.Create('TE_FORM_EXTENSION.RestoreOriginalControlsState: formInfo is Nil');
    end;

    if (Assigned(formInfo)) then
    begin
      formfunc:=FORM_RESTOREORIGINALCONTROLSTATE;
      EnumerateEinterface(Self, formInfo);
    end;
end;


procedure TE_FORM_EXTENSION.SetNormalColor;
var
   formInfo   :TE_FORM_INFO;
begin
   formInfo:=GetExtensionInfo();
   if (not Assigned(formInfo)) then
   begin
     raise Exception.Create('TE_FORM_EXTENSION.SetNormalColor: formInfo is Nil');
   end;

    if (Assigned(formInfo)) then
    begin
      formfunc:=FORM_SETNORMALCOLOR;
      EnumerateEinterface(Self, formInfo);
    end;
end;

procedure TE_FORM_EXTENSION.SetErrorColor;
var
   formInfo   :TE_FORM_INFO;
begin
   formInfo:=GetExtensionInfo();
   if (not Assigned(formInfo)) then
   begin
     raise Exception.Create('TE_FORM_EXTENSION.SetErrorColor: formInfo is Nil');
   end;

    if (Assigned(formInfo)) then
    begin
      formfunc:=FORM_SETERRORCOLOR;
      EnumerateEinterface(Self, formInfo);
    end;
end;

procedure TE_FORM_EXTENSION.Validate();
var
   formInfo   :TE_FORM_INFO;
begin
    formInfo:=GetExtensionInfo();
    if (not Assigned(formInfo)) then
    begin
      raise Exception.Create('TE_FORM_EXTENSION.Validate: formInfo is Nil');
    end;

    if (Assigned(formInfo)) then
    begin
      formfunc:=FORM_VALIDATE;
      formInfo.IsValid:=True;
      EnumerateEinterface(Self, formInfo);
    end;
end;

procedure TE_FORM_EXTENSION.Clear();
var
   formInfo   :TE_FORM_INFO;
begin
    formInfo:=GetExtensionInfo();
    if (not Assigned(formInfo)) then
    begin
      raise Exception.Create('TE_FORM_EXTENSION.Clear: formInfo is Nil');
    end;

    if (Assigned(formInfo)) then
    begin
      formfunc:=FORM_CLEAR;
      EnumerateEinterface(Self, formInfo);
    end;
end;

procedure TE_FORM_EXTENSION.SetEnable(new_value:boolean);
var
   formInfo   :TE_FORM_INFO;
begin
    formInfo:=GetExtensionInfo();
    if (not Assigned(formInfo)) then
    begin
      raise Exception.Create('TE_FORM_EXTENSION.SetEnable: formInfo is Nil');
    end;

    if (Assigned(formInfo)) then
    begin
      formfunc:=FORM_SETENABLE;
      gnewValue:=new_value;
      EnumerateEinterface(Self, formInfo);
    end;
 end;

procedure TE_FORM_EXTENSION.SetReadOnly(new_value:boolean);
var
   formInfo   :TE_FORM_INFO;
begin
   formInfo:=GetExtensionInfo();
   if (not Assigned(formInfo)) then
   begin
     raise Exception.Create('TE_FORM_EXTENSION.SetReadOnly: formInfo is Nil');
   end;

    if (Assigned(formInfo)) then
    begin
      formfunc:=FORM_SETREADONLY;
      gnewValue:=new_value;
      EnumerateEinterface(Self, formInfo);
    end;
end;

procedure TE_FORM_EXTENSION.SetVisible(new_value:boolean);
var
   formInfo   :TE_FORM_INFO;
begin
   formInfo:=GetExtensionInfo();
   if (not Assigned(formInfo)) then
    begin
      raise Exception.Create('TE_FORM_EXTENSION.SetVisible: formInfo is Nil');
    end;

    if (Assigned(formInfo)) then
    begin
      formfunc:=FORM_SETVISIBLE;
      gnewValue:=new_value;
      EnumerateEinterface(Self, formInfo);
    end;
end;







// TE_FORM_INFO adattartalmanak elerese
function TE_FORM_EXTENSION.getIsValid():boolean;
var formInfo :TE_FORM_INFO;
begin
  Result:=false;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.IsValid;
  end;
end;
procedure TE_FORM_EXTENSION.setIsValid(new_value:boolean);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.IsValid:=new_value;
  end;
end;


function TE_FORM_EXTENSION.getIsModified():boolean;
var formInfo :TE_FORM_INFO;
begin
  Result:=false;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.IsModified;
  end;
end;
procedure TE_FORM_EXTENSION.setIsModified(new_value:boolean);
var formInfo :TE_FORM_INFO;
begin
   formInfo:=GetExtensionInfo();
    if (Assigned(formInfo)) then
    begin
       formInfo.IsModified:=new_value;
    end;
end;


function TE_FORM_EXTENSION.getLookIsModified():boolean;
var formInfo :TE_FORM_INFO;
begin
  Result:=false;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.LookIsModified;
  end;
end;
procedure TE_FORM_EXTENSION.setLookIsModified(new_value:boolean);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.LookIsModified:=new_value;
  end;
end;


function TE_FORM_EXTENSION.getReadOnlyProp():boolean;
var formInfo :TE_FORM_INFO;
begin
  Result:=false;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.ReadOnlyProp;
  end;
end;
procedure TE_FORM_EXTENSION.setReadOnlyProp(new_value:boolean);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.ReadOnlyProp:=new_value;
  end;
end;


function TE_FORM_EXTENSION.getNonValidObjectName():string;
var formInfo :TE_FORM_INFO;
begin
  Result:='';
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.NonValidObjectName;
  end;
end;
procedure TE_FORM_EXTENSION.setNonValidObjectName(new_value:string);
var formInfo :TE_FORM_INFO;
begin
   formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.NonValidObjectName:=new_value;
  end;
end;


function TE_FORM_EXTENSION.getLastKey():Word;
var formInfo :TE_FORM_INFO;
begin
  Result:=0;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.LastKey;
  end;
end;
procedure TE_FORM_EXTENSION.setLastKey(new_value:Word);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.LastKey:=new_value;
  end;
end;


function TE_FORM_EXTENSION.getLastShift():TShiftState;
var formInfo :TE_FORM_INFO;
begin
  Result:=[];
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.LastShift;
  end;
end;
procedure TE_FORM_EXTENSION.setLastShift(new_value:TShiftState);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.LastShift:=new_value;
  end;
end;


function TE_FORM_EXTENSION.getEnabledFocusColor():boolean;
var formInfo :TE_FORM_INFO;
begin
  Result:=false;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.EnabledFocusColor;
  end;
end;
procedure TE_FORM_EXTENSION.setEnabledFocusColor(new_value:boolean);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.EnabledFocusColor:=new_value;
  end;
end;


function TE_FORM_EXTENSION.getFormName():string;
var formInfo :TE_FORM_INFO;
begin
  Result:='';
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.FormName;
  end;
end;
procedure TE_FORM_EXTENSION.setFormName(new_value:string);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.FormName:=new_value;
  end;
end;


function TE_FORM_EXTENSION.getForm():TWebForm;
var formInfo :TE_FORM_INFO;
begin
  Result:=Nil;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.Form;
  end;
end;
procedure TE_FORM_EXTENSION.setForm(new_value:TWebForm);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.Form:=new_value;
  end;
end;
//

function  TE_FORM_EXTENSION.getColor_NormalFg() : string;
var formInfo :TE_FORM_INFO;
begin
  Result:=COLOR_NORMALFG_CSS;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.Color_NormalFg;
  end;
end;
procedure TE_FORM_EXTENSION.setColor_NormalFg(new_value:string);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.Color_NormalFg:=new_value;
  end;
end;


function  TE_FORM_EXTENSION.getColor_NormalBg() : string;
var formInfo :TE_FORM_INFO;
begin
  Result:=COLOR_NORMALBG_CSS;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.Color_NormalBg;
  end;
end;
procedure TE_FORM_EXTENSION.setColor_NormalBg(new_value:string);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.Color_NormalBg:=new_value;
  end;
end;


function  TE_FORM_EXTENSION.getColor_ErrorFg() : string;
var formInfo :TE_FORM_INFO;
begin
  Result:=COLOR_ERRORFG_CSS;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.Color_ErrorFg;
  end;
end;
procedure TE_FORM_EXTENSION.setColor_ErrorFg(new_value:string);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.Color_ErrorFg:=new_value;
  end;
end;


function  TE_FORM_EXTENSION.getColor_ErrorBg() : string;
var formInfo :TE_FORM_INFO;
begin
  Result:=COLOR_ERRORBG_CSS;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.Color_ErrorBg;
  end;
end;
procedure TE_FORM_EXTENSION.setColor_ErrorBg(new_value:string);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.Color_ErrorBg:=new_value;
  end;
end;


function  TE_FORM_EXTENSION.getColor_FocusFg() : string;
var formInfo :TE_FORM_INFO;
begin
  Result:=COLOR_FOCUSFG_CSS;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.Color_FocusFg;
  end;
end;
procedure TE_FORM_EXTENSION.setColor_FocusFg(new_value:string);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
    formInfo.Color_FocusFg:=new_value;
  end;
end;


function  TE_FORM_EXTENSION.getColor_FocusBg() : string;
var formInfo :TE_FORM_INFO;
begin
  Result:=COLOR_FOCUSBG_CSS;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.Color_FocusBg;
  end;
end;
procedure TE_FORM_EXTENSION.setColor_FocusBg(new_value:string);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.Color_FocusBg:=new_value;
  end;
end;


function  TE_FORM_EXTENSION.getStatus() : integer;
var formInfo :TE_FORM_INFO;
begin
  Result:=0;
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      Result := formInfo.Status;
  end;
end;
procedure TE_FORM_EXTENSION.setStatus(new_value:integer);
var formInfo :TE_FORM_INFO;
begin
  formInfo:=GetExtensionInfo();
  if (Assigned(formInfo)) then
  begin
      formInfo.Status:=new_value;
  end;
end;

end.