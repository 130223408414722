unit web_eo_string;

interface

uses
   System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
   WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, WEBLib.WebCtrls,
   web_eo_common, web_eo_iif
   ;

type
    TWE_STRING = class(TWebEdit, E_INTERFACE, E_OBJECT)
    private
        {%region E_INTERFACE}
        FIsModified     : boolean;
        FHint_Error     : string;          // ha hibas egy control
        FHintSave       : string;
        FIsValid        : boolean;         // set the user in the OnValidateEvent(), def:TRUE
        FParentForm     : TWebForm;
        FHandleEsc      : boolean;
        FStopWhenError  : boolean;
        FLookIsModified : boolean;
        FPermission     : Integer;
        FObjectID       : Integer;
        FCtrlAttr       : ControlAttributes;
        {%endregion E_INTERFACE}

        FOnValidateEvent:TOnvalidateEvent;
        FMaxLength      : Integer;

        // variable
        LastKeyIsEsc    : boolean;
        Save_Hint       : string;          // save the original hint
        FocusOnControl  :boolean;
    
    protected
        {%region E_INTERFACE}
        function  getIsModified:boolean;
        procedure setIsModified(new_value:boolean);

        function  getHint_Error:string;
        procedure setHint_Error(new_value:string);

        function  getHintSave:string;
        procedure setHintSave(new_value:string);

        function  getIsValid:boolean;
        procedure setIsValid(new_value:boolean);

        function  getParentForm:TWebForm;
        procedure setParentForm(new_value:TWebForm);

        function  getHandleEsc:boolean;
        procedure setHandleEsc(new_value:boolean);

        function  getStopWhenError:boolean;
        procedure setStopWhenError(new_value:boolean);

        function  getLookIsModified:boolean;
        procedure setLookIsModified(new_value:boolean);

        function  getPermission:Integer;
        procedure setPermission(new_value:Integer);

        function  getObjectID:Integer;
        procedure setObjectID(new_value:Integer);
        {%endregion E_INTERFACE}

        function  getEnabled:boolean; overload;
        procedure setEnabled(new_value:boolean); overload;

        function  getVisible:boolean; overload;
        procedure setVisible(new_value:boolean); overload;

        function  getReadOnly:boolean; overload;
        procedure setReadOnly(new_value:boolean); overload;

        procedure Loaded; override;

    public
        {%region E_INTERFACE}
        function  getControlAttributes:ControlAttributes;
        procedure setControlAttributes(new_value:ControlAttributes);

        property  IsModified     :boolean  read getIsModified  write setIsModified;
        property  HintSave       :string  read getHintSave  write setHintSave;
        property  ParentForm     :TWebForm   read getParentForm  write setParentForm;
        {%endregion E_INTERFACE}

        IsLoaded:boolean;

        constructor Create(AOwner:TComponent); override;

        procedure KeyDown(var Key: Word; Shift: TShiftState); override;
        procedure KeyPress(var Key: Char); override;
        procedure DoExit; override;
        procedure DoEnter; override;

        procedure SetColorEif;  //safecall;
        procedure Clear();

    published
        {%region E_INTERFACE}
        property Hint_Error      :string  read getHint_Error  write setHint_Error;
        property IsValid         :boolean read getIsValid     write setIsValid;
        property HandleEsc       :boolean read getHandleEsc    write setHandleEsc;
        property StopWhenError   :boolean read getStopWhenError write setStopWhenError;
        property LookIsModified  :boolean read getLookIsModified  write setLookIsModified;
        property Permission      :Integer read getPermission write setPermission;
        property ObjectID        :Integer read getObjectID write setObjectID;
        {%endregion E_INTERFACE}

        property OnValidateEvent :TOnValidateEvent read FOnValidateEvent  write FOnValidateEvent;
        property MaxLength       :Integer read FMaxLength  write FMaxLength;
        property Enabled         :boolean read getEnabled    write setEnabled;
        property Visible         :boolean read getVisible    write setVisible;
        property ReadOnly        :boolean read getReadOnly   write setReadOnly;

    end;


implementation

uses
    web_eform, web_permission_helper_u;

var
   in_setPermission:Boolean=False;


constructor TWE_STRING.Create(AOwner:TComponent);
begin
   inherited Create(AOwner);

   IsLoaded        := FALSE;
   HandleEsc       := TRUE;
   StopWhenError   := FALSE;
   FIsModified     := FALSE;
   FLookIsModified := True;
   FMaxLength      := 0;
   FPermission     := 0;
   FObjectID       := 0;
end;


procedure TWE_STRING.Loaded;
begin
   inherited;
   setHintSave(Hint);       // save the origanal hint
   setIsValid(TRUE);        // the field is valid
   LastKeyIsEsc:=FALSE;
   Save_Hint   :=Hint;
   FocusOnControl:=FALSE;

   IsLoaded:=TRUE;
   SetColorEif();
end;

{%region E_INTERFACE}
function  TWE_STRING.getIsModified:boolean;
begin
   Result:=FIsModified;
end;
procedure TWE_STRING.setIsModified(new_value:boolean);
begin
   FIsModified:=new_value;
end;

function  TWE_STRING.getHint_Error:string;
begin
   Result:=FHint_Error;
end;
procedure TWE_STRING.setHint_Error(new_value:string);
begin
   FHint_Error:=new_value;
end;

function TWE_STRING.getHintSave:string;
begin
   Result:=FHintSave;
end;
procedure TWE_STRING.setHintSave(new_value:string);
begin
   FHintSave:=new_value;
end;

function TWE_STRING.getIsValid:boolean;
begin
   if IsLoaded then
   begin
      if Assigned(FOnValidateEvent) then  // call the verify function
         FOnValidateEvent(Self);
   end;
   Result:=FIsValid;
 end;
 procedure TWE_STRING.setIsValid(new_value:boolean);
 begin
   FIsValid:=new_value;
   if IsLoaded then
   begin
      SetColorEif();

      if (new_value=FALSE) then
      begin
        ParentForm.SetNonValidObjectName(Name);
      end;
   end;
end;

function TWE_STRING.getParentForm:TWebForm;
begin
    FParentForm := GetParentFormFunc(Self);
    Result:=FParentForm;
end;
procedure TWE_STRING.setParentForm(new_value:TwebForm);
begin
   FParentForm:=new_value;
end;

function  TWE_STRING.getHandleEsc:boolean;
begin
   Result:=FHandleEsc;
end;
procedure TWE_STRING.setHandleEsc(new_value:boolean);
begin
   FHandleEsc:=new_value;
end;

function  TWE_STRING.getStopWhenError:boolean;
begin
   Result:=FStopWhenError;
end;
procedure TWE_STRING.setStopWhenError(new_value:boolean);
begin
   FStopWhenError:=new_value;
end;

function  TWE_STRING.getLookIsModified:boolean;
begin
   Result:=FLookIsModified;
end;

procedure TWE_STRING.setLookIsModified(new_value:boolean);
begin
   FLookIsModified:=new_value;
end;

function  TWE_STRING.getPermission:Integer;
begin
   Result:=FPermission;
end;
procedure TWE_STRING.setPermission(new_value:Integer);
begin
   if (not in_setPermission) then
   begin
      try
        in_setPermission:=True;
        FPermission:=new_value;
        TPermissionHelper.SetPermission(Self, new_value);
      finally
        in_setPermission:=False;
      end;
   end;
end;

function  TWE_STRING.getObjectID:Integer;
begin
   Result:=FObjectID;
end;
procedure TWE_STRING.setObjectID(new_value:Integer);
begin
   FObjectID:=new_value;
end;
//
function  TWE_STRING.getControlAttributes():ControlAttributes;
begin
  Result:=FCtrlAttr;
end;
procedure TWE_STRING.setControlAttributes(new_value:ControlAttributes);
begin
  FCtrlAttr:=new_value;
end;

{%endregion E_INTERFACE}


// set the colors when, parent is E_FORM !!!
//  if parent is E_GRID or other, then not change colors!!
procedure TWE_STRING.SetColorEif();
begin
  if (ParentForm is TWebForm) then
  begin
      if FIsValid then
      begin
        if FocusOnControl and ParentForm.EnabledFocusColor then
        begin
            ElementClassName := SetFocusColor(ElementClassName); //Font.Color:=Color_FocusFg;  Color     :=Color_FocusBg;
            Hint      :=Save_Hint;
        end
        else
        begin
            ElementClassName := SetNormalColor(ElementClassName); //Font.Color:=CustomNormalFg;  Color     :=CustomNormalBg;
            Hint      :=Save_Hint;
        end;
      end
      else
      begin
        ElementClassName := SetErrorColor(ElementClassName);  //Font.Color:=TWebForm(ParentForm).Color_ErrorFg;  Color     :=TWebForm(ParentForm).Color_ErrorBg;
        Hint      :=Hint_Error;
      end;
  end;
end;

procedure TWE_STRING.Clear();
begin
  Text:='';
end;

function  TWE_STRING.getEnabled:boolean;
begin
   Result:=Inherited Enabled;
end;

procedure TWE_STRING.setEnabled(new_value:boolean);
begin
   if (in_setPermission) then
   begin
      Inherited Enabled:=new_value;
   end
   else
   begin
      if (TPermissionHelper.IsEnabled(Permission)) then
      begin
         Inherited Enabled:=new_value;
      end;
   end;
end;

function  TWE_STRING.getVisible:boolean;
begin
   Result:=Inherited Visible;
end;

procedure TWE_STRING.setVisible(new_value:boolean);
begin
   if (in_setPermission) then
   begin
      Inherited Visible:=new_value;
   end
   else
   begin
      if (TPermissionHelper.IsVisible(Permission)) then
      begin
         Inherited Visible:=new_value;
      end;
   end;
end;

function  TWE_STRING.getReadOnly:boolean;
begin
   Result:=Inherited ReadOnly;
end;

procedure TWE_STRING.setReadOnly(new_value:boolean);
begin
   if (in_setPermission) then
   begin
      Inherited ReadOnly:=new_value;
   end
   else
   begin
      if (not TPermissionHelper.IsReadOnly(Permission)) then
      begin
         Inherited ReadOnly:=new_value;
      end;
   end;
end;



procedure TWE_STRING.KeyDown(var Key: Word; Shift: TShiftState);
var
   ws:String;
begin
   ws:=Text;
   if ((MaxLength=0) or (Length(ws)<Maxlength) or (Key=VK_RETURN) or (Key=VK_ESCAPE) or (Key=VK_TAB)) then
   begin
       inherited KeyDown(Key, Shift);
       if (ParentForm is TWebForm) then
       begin
          ParentForm.LastKey   :=Key;
          ParentForm.LastShift :=Shift;
       end;
       if (not ReadOnly) then
       begin
          LastKeyIsEsc:=(Key=VK_ESCAPE);
          if (Key <> VK_ESCAPE) then
          begin
             if (FLookIsModified) then
             begin
                FIsModified:=TRUE;
             end;
             if ((FIsValid=FALSE) and (Key<>0)) then
             begin
                FIsValid:=TRUE;
                SetColorEif();
             end;
          end;
       end;
   end
   else
   begin
      // az edit reszben a mozgast engedelyezni kell + torlesek
      if ((Key<>VK_BACK) and (Key<>VK_DELETE) and
          (Key<>VK_LEFT) and (Key<>VK_RIGHT) and (Key<>VK_HOME) and (Key<>VK_END)) then
      begin
         Key:=0;
      end;
   end;
end;


procedure TWE_STRING.KeyPress(var Key: Char);
begin
   inherited KeyPress(Key);
   (*
   if (not ReadOnly) then
   begin

      if (CharinSet(Key, ['0'..'9','.','+','-','t','T',FormatSettings.DecimalSeparator])) then
      begin
         Ertelmez(Key);
         Megjelenit(j_main);
      end;
   end;
   // FONTOS!! ha ==0, akkor nem jelenik meg a beutott ertek
   Key:=#0;
   *)
end;



procedure TWE_STRING.DoExit;
begin
   inherited;
   FocusOnControl:=FALSE;
   if (not LastKeyIsEsc) then
   begin
      if Assigned(FOnValidateEvent) then
      begin
         FOnValidateEvent(Self);
         if (FStopWhenError and (not FIsValid) and (Enabled)) then
         begin
            SetFocus;
         end;
      end;
   end;
   SetColorEif();
end;


procedure TWE_STRING.DoEnter;
begin
   inherited;
   FIsValid:=TRUE;
   FocusOnControl:=TRUE;
   SetColorEif();
end;





end.