unit web_eo_iif;

interface
type
    // generics (generikus) megoldas barmely tipusra jo
    // azert ez a jobb megoldas, mert ez tipusos
    // hasznalata: 
    // var i0:integer;
    // i0:=IIFF<Integer>.Value(('AA'='BB'), 5, 3);
   generic IIFF<T> = class sealed
   public
       constructor Create();
       class function Value(Condition:boolean; TruePart:T; FalsePart : T) : T;
   end;


implementation

constructor IIFF<T>.Create();
begin
 // Do nothing.  This cannot be called.
end;

class function IIFF<T>.Value(Condition:boolean; TruePart:T; FalsePart : T) : T ;
begin
  if (Condition) then
  begin
     Result:=TruePart;
  end
  else
  begin
     Result:=FalsePart;
  end;
end;

end.

