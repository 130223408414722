unit web_es_system;

{$mode objfpc}{$H+}

interface

uses sysutils;


function MakeExcepptionMessages(ex:Exception) : string;

//function DumpCallStack():string;
//function DumpExceptionCallStack(E: Exception) :string;


procedure ClearBit(var Value: Integer; Index: Byte);
procedure SetBit(var Value: Integer; Index: Byte);
procedure PutBit(var Value: Integer; Index: Byte; State: Boolean);
function TestBit(Value: Integer; Index: Byte): Boolean;



implementation

uses
  Classes;




{%region 'Exception handling'}
// egy stringben adja vissza az egymasba epult exception-ok szoveget (Message)
// itt nincsen InnerException, ezert csak az exception infot szedi ossze
function MakeExcepptionMessages(ex:Exception) : string;
begin
   Result:='('+string(ex.ClassName)+')  Message='+string(ex.Message);
end;

// kivalt egy uj exception-t, DE beleagyazza a megadottat
//procedure RaiseException(ex:Exception);
//begin
//   Exception..RaiseOuterException(ex);
//end;




// function DumpCallStack():string;
// var
//   I: Longint;
//   prevbp: Pointer;
//   CallerFrame,
//   CallerAddress,
//   bp: Pointer;
//   Report: string;
// const
//   MaxDepth = 20;
// begin
//   Report := '';
//   bp := get_frame;
//   // This trick skip SendCallstack item
//   // bp:= get_caller_frame(get_frame);
//   try
//     prevbp := bp - 1;
//     I := 0;
//     while bp > prevbp do
//     begin
//        CallerAddress := get_caller_addr(bp);
//        CallerFrame := get_caller_frame(bp);
//        if (CallerAddress = nil) then
//          Break;
//        Report := Report + BackTraceStrFunc(CallerAddress) + LineEnding;
//        Inc(I);
//        if (I >= MaxDepth) or (CallerFrame = nil) then
//          Break;
//        prevbp := bp;
//        bp := CallerFrame;
//     end;
//    except
//      { prevent endless dump if an exception occured }
//    end;
//   Result:=Report;
// end;

// function DumpExceptionCallStack(E: Exception) :string;
// var
//   I: Integer;
//   Frames: PPointer;
//   Report: string;
// begin
//   Report := 'Program exception! ' + LineEnding +
//     'Stacktrace:' + LineEnding + LineEnding;
//   if E <> nil then
//   begin
//     Report := Report + 'Exception class: ' + E.ClassName + LineEnding +
//     'Message: ' + E.Message + LineEnding;
//   end;
//   Report := Report + BackTraceStrFunc(ExceptAddr);
//   Frames := ExceptFrames;
//   for I := 0 to ExceptFrameCount - 1 do
//     Report := Report + LineEnding + BackTraceStrFunc(Frames[I]);
//   result:=Report;
// end;
{%endregion 'Exception handling'}




{%region 'bit operation'}
procedure ClearBit(var Value: Integer; Index: Byte);
begin
  Value := Value and ((Integer(1) shl Index) xor High(Integer));
end;

procedure SetBit(var Value: Integer; Index: Byte);
begin
  Value:=  Value or (Integer(1) shl Index);
end;

procedure PutBit(var Value: Integer; Index: Byte; State: Boolean);
begin
  Value := (Value and ((Integer(1) shl Index) xor High(Integer))) or (Integer(State) shl Index);
end;

function TestBit(Value: Integer; Index: Byte): Boolean;
begin
  Result := ((Value shr Index) and 1) = 1;
end;
{%endregion 'bit operation'}


end.

