unit web_eo_button;

interface
uses
   System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
   WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, WEBLib.WebCtrls, WEBLib.Buttons,
   web_eo_common, web_eo_iif
   ;

type
    //TWE_BUTTON = class(TWebBitBtn, E_INTERFACE)
    TWE_BUTTON = class(TWebButton, E_INTERFACE, E_OBJECT)
    private
        {%region E_INTERFACE}
        FIsModified     : boolean;
        FHint_Error     : string;          // ha hibas egy control
        FHintSave       : string;
        FIsValid        : boolean;         // set the user in the OnValidateEvent(), def:TRUE
        FParentForm     : TWebForm;
        FHandleEsc      : boolean;
        FStopWhenError  : boolean;
        FLookIsModified : boolean;
        FPermission     : Integer;
        FObjectID       : Integer;
        FCtrlAttr       : ControlAttributes;
        {%endregion E_INTERFACE}

        FReadOnly       : Boolean;

        FOnValidateEvent:TOnvalidateEvent;

        // variable
        LastKeyIsEsc    : boolean;
        Save_Hint       : string;          // save the original hint
        FocusOnControl  : boolean;
    
    protected
        {%region E_INTERFACE}
        function  getIsModified:boolean;
        procedure setIsModified(new_value:boolean);

        function  getHint_Error:string;
        procedure setHint_Error(new_value:string);

        function  getHintSave:string;
        procedure setHintSave(new_value:string);

        function  getIsValid:boolean;
        procedure setIsValid(new_value:boolean);

        function  getParentForm:TWebForm;
        procedure setParentForm(new_value:TWebForm);

        function  getHandleEsc:boolean;
        procedure setHandleEsc(new_value:boolean);

        function  getStopWhenError:boolean;
        procedure setStopWhenError(new_value:boolean);

        function  getLookIsModified:boolean;
        procedure setLookIsModified(new_value:boolean);

        function  getPermission:Integer;
        procedure setPermission(new_value:Integer);

        function  getObjectID:Integer;
        procedure setObjectID(new_value:Integer);
        {%endregion E_INTERFACE}

        function  getEnabled:boolean; overload;
        procedure setEnabled(new_value:boolean); overload;

        function  getVisible:boolean; overload;
        procedure setVisible(new_value:boolean); overload;

        function  getReadOnly:boolean; overload;
        procedure setReadOnly(new_value:boolean); overload;

        procedure Loaded; override;

    public
        {%region E_INTERFACE}
        function  getControlAttributes:ControlAttributes;
        procedure setControlAttributes(new_value:ControlAttributes);


        property  IsModified     :boolean  read getIsModified  write setIsModified;
        property  HintSave       :string  read getHintSave  write setHintSave;
        property  ParentForm     :TWebForm   read getParentForm  write setParentForm;
        {%endregion E_INTERFACE}

        IsLoaded:boolean;

        constructor Create(AOwner:TComponent); override;

        procedure DoExit; override;
        procedure DoEnter; override;

        procedure SetColorEif;  //safecall;
        procedure Clear();

    published
        {%region E_INTERFACE}
        property Hint_Error      :string  read getHint_Error  write setHint_Error;
        property IsValid         :boolean read getIsValid     write setIsValid;
        property HandleEsc       :boolean read getHandleEsc    write setHandleEsc;
        property StopWhenError   :boolean read getStopWhenError write setStopWhenError;
        property LookIsModified  :boolean read getLookIsModified  write setLookIsModified;
        property Permission      :Integer read getPermission write setPermission;
        property ObjectID        :Integer read getObjectID write setObjectID;
        {%endregion E_INTERFACE}

        property OnValidateEvent :TOnValidateEvent read FOnValidateEvent  write FOnValidateEvent;
        property Enabled         :boolean read getEnabled    write setEnabled;
        property Visible         :boolean read getVisible    write setVisible;
        property ReadOnly        :boolean read getReadOnly   write setReadOnly;

        property  OnEnter;
        property  OnExit;
    end;


implementation

uses
    web_eform, web_permission_helper_u;

var
   in_setPermission:Boolean=False;


constructor TWE_BUTTON.Create(AOwner:TComponent);
begin
   inherited Create(AOwner);

   IsLoaded        := FALSE;
   HandleEsc       := TRUE;
   StopWhenError   := FALSE;
   FIsModified     := FALSE;
   FLookIsModified := True;
   FPermission     := 0;
   FObjectID       := 0;
end;


procedure TWE_BUTTON.Loaded;
begin
   inherited;
   setHintSave(Hint);       // save the origanal hint
   setIsValid(TRUE);        // the field is valid
   LastKeyIsEsc:=FALSE;
   Save_Hint   :=Hint;
   FocusOnControl:=FALSE;

   IsLoaded:=TRUE;
   SetColorEif();
end;

{%region E_INTERFACE}
function  TWE_BUTTON.getIsModified:boolean;
begin
   Result:=FIsModified;
end;
procedure TWE_BUTTON.setIsModified(new_value:boolean);
begin
   FIsModified:=new_value;
end;

function  TWE_BUTTON.getHint_Error:string;
begin
   Result:=FHint_Error;
end;
procedure TWE_BUTTON.setHint_Error(new_value:string);
begin
   FHint_Error:=new_value;
end;

function TWE_BUTTON.getHintSave:string;
begin
   Result:=FHintSave;
end;
procedure TWE_BUTTON.setHintSave(new_value:string);
begin
   FHintSave:=new_value;
end;

function TWE_BUTTON.getIsValid:boolean;
begin
   if IsLoaded then
   begin
      if Assigned(FOnValidateEvent) then  // call the verify function
         FOnValidateEvent(Self);
   end;
   Result:=FIsValid;
 end;
 procedure TWE_BUTTON.setIsValid(new_value:boolean);
 begin
   FIsValid:=new_value;
   if IsLoaded then
   begin
      SetColorEif();

      if (new_value=FALSE) then
      begin
        ParentForm.SetNonValidObjectName(Name);
      end;
   end;
end;

function TWE_BUTTON.getParentForm:TWebForm;
begin
   FParentForm := GetParentFormFunc(Self);
   Result:=FParentForm;
end;
procedure TWE_BUTTON.setParentForm(new_value:TwebForm);
begin
   FParentForm:=new_value;
end;

function  TWE_BUTTON.getHandleEsc:boolean;
begin
   Result:=FHandleEsc;
end;
procedure TWE_BUTTON.setHandleEsc(new_value:boolean);
begin
   FHandleEsc:=new_value;
end;

function  TWE_BUTTON.getStopWhenError:boolean;
begin
   Result:=FStopWhenError;
end;
procedure TWE_BUTTON.setStopWhenError(new_value:boolean);
begin
   FStopWhenError:=new_value;
end;

function  TWE_BUTTON.getLookIsModified:boolean;
begin
   Result:=FLookIsModified;
end;

procedure TWE_BUTTON.setLookIsModified(new_value:boolean);
begin
   FLookIsModified:=new_value;
end;

function  TWE_BUTTON.getPermission:Integer;
begin
   Result:=FPermission;
end;
procedure TWE_BUTTON.setPermission(new_value:Integer);
begin
   if (not in_setPermission) then
   begin
      try
        in_setPermission:=True;
        FPermission:=new_value;
        TPermissionHelper.SetPermission(Self, new_value);
      finally
        in_setPermission:=False;
      end;
   end;
end;

function  TWE_BUTTON.getObjectID:Integer;
begin
   Result:=FObjectID;
end;
procedure TWE_BUTTON.setObjectID(new_value:Integer);
begin
   FObjectID:=new_value;
end;

function  TWE_BUTTON.getControlAttributes():ControlAttributes;
begin
  Result:=FCtrlAttr;
end;
procedure TWE_BUTTON.setControlAttributes(new_value:ControlAttributes);
begin
  FCtrlAttr:=new_value;
end;
{%endregion E_INTERFACE}


procedure TWE_BUTTON.SetColorEif();
begin
//  Not implemented
end;

procedure TWE_BUTTON.Clear();
begin
end;

function  TWE_BUTTON.getEnabled:boolean;
begin
   Result:=Inherited Enabled;
end;

procedure TWE_BUTTON.setEnabled(new_value:boolean);
begin
   if (in_setPermission) then
   begin
      Inherited Enabled:=new_value;
   end
   else
   begin
      if (TPermissionHelper.IsEnabled(Permission)) then
      begin
         Inherited Enabled:=new_value;
      end;
   end;
end;

function  TWE_BUTTON.getVisible:boolean;
begin
   Result:=Inherited Visible;
end;

procedure TWE_BUTTON.setVisible(new_value:boolean);
begin
   if (in_setPermission) then
   begin
      Inherited Visible:=new_value;
   end
   else
   begin
      if (TPermissionHelper.IsVisible(Permission)) then
      begin
         Inherited Visible:=new_value;
      end;
   end;
end;

function  TWE_BUTTON.getReadOnly:boolean;
begin
   Result:=FReadOnly;
end;

procedure TWE_BUTTON.setReadOnly(new_value:boolean);
begin
   if (in_setPermission) then
   begin
      FReadOnly:=new_value;
      Enabled:=not FReadOnly;
   end
   else
   begin
      if (not TPermissionHelper.IsReadOnly(Permission)) then
      begin
         FReadOnly:=new_value;
         Enabled:=not FReadOnly;
      end;
   end;
end;




procedure TWE_BUTTON.DoExit;
begin
   inherited;
   FocusOnControl:=FALSE;
   if (not LastKeyIsEsc) then
   begin
      if Assigned(FOnValidateEvent) then
      begin
         FOnValidateEvent(Self);
         if (FStopWhenError and (not FIsValid) and (Enabled)) then
         begin
            SetFocus;
         end;
      end;
   end;
   SetColorEif();
end;


procedure TWE_BUTTON.DoEnter;
begin
   inherited;
   FIsValid:=TRUE;
   FocusOnControl:=TRUE;
   SetColorEif();
end;

end.