unit web_es_string;

{$mode objfpc}{$H+}

interface


function Lower(const _s:string) : string;                          // kisbeture konvertal - ekezet helyesen
function Upper(const _s:string) : string;                          // nagybeture konvertal - ekezet helyesen
procedure SetLen(var _s:string; const _len:integer; const _ch:char);  // adott hosszra kiegesziti az adott ch-val
function Space(const _len:integer) : string;                       // visszaad len hosszba szokozoket
function LTrim(const _s:string) : string;                          // bal  oldali szokozok leszedese
function RTrim(const _s:string) : string;                          // jobb oldali szokozok leszedese
function Alltrim(const _s:string) : string;                        // leszedi az osszes szokozt elol es hatuls is
function Center(const _s:string; const _len:integer) : string;     // visszaadja kozepre igazitva
function ValI(s : string) : integer;                               // visszaadja numerikusan
function ValL(s : string) : longint;                               // visszaadja numerikusan
function ValD(s : string) : double;                                // visszaadja numerikusan
function ValE(s : string) : extended;                              // visszaadja numerikusan
function StrI(const _i:longint; const _len:integer; const _zeros:boolean=FALSE) : string; overload; // str-e alakitja, ha zeros==TRUE => feltolti 0-val
function StrD(const _e:extended; const _len:integer; const _dec:integer) : string; overload;        // str-e alakitja, ha zeros==TRUE => feltolti 0-val
function StrTrim(const _i:longint; const _len:integer; const _zeros:boolean=FALSE) : string; overload; // str-e alakitja, ha zeros==TRUE => feltolti 0-val
function StrTrim(const _e:extended; const _len:integer; const _dec:integer) : string; overload;        // str-e alakitja, ha zeros==TRUE => feltolti 0-val


function Transform(const _ii:LongInt; const _pict:string) : string; overload;    // pict alapjan formaz. pict-ben tizedespont es ezredesvesszo van!! az eredmeny 'local' helyes
function Transform(const _r: extended; const _pict:String) : string; overload;   // pict alapjan formaz. pict-ben tizedespont es ezredesvesszo van!! az eredmeny 'local' helyes
function Left(const _s:string; const _len:integer) : string;
function Right(const _s:string; const _len:integer) : string;
function Mid(const _s:string; const _pos:integer; const _len:integer) : string;
function Num(const _s:string; const _leading_space:boolean=FALSE) : string;         // ha sp==TRUE  =>'  9,999.99' => '9999.99'
                                                                                    // ha sp==FALSE =>'  9,999.99' => '  9999.99'
function Match(const _mit:string; const _miben:string; const _pos:integer):integer; // minta keresese az adott pos-tol. Ha nincs =>-1

// szoveg feldolgozo fv-nyek
            // pos-tol kezdve megkeresi az elso nem szokoz|tab karaktert,
            // es pos raall. Ha nincs, akkor pos==-1
procedure GetSpace(const _s:string; var _pos:integer);

            // post-tol kezdve addig halad, ameddig a karakterek bent
            // vannak set-ben, vagy vege a sornak
function GetWord(const _s:string; var _pos:integer; const _set:string) : string;

            // pos-tol kezdve atlepi azt a szovegreszt, ami chleft
            // chright kozt van. Ha vege a sornak, akkor pos==-1
procedure Pair(const _s:string; var _pos:integer; _chleft:char; _chright:char);

            // pos-tol kezdve visszaadja azt a szovegreszt, ami ami chleft
            // chright kozt van. Ha vege a sornak, akkor pos==-1
            // escch==TRUE ==> vegye figyelembe a \ karaktert
function GetPair(const _s:string; var _pos:integer; _chleft:char; _chright:char; _escch:boolean=FALSE): string;
            // lekezeli az egymasba agyazott stringeket is (chleft!=chright)
function GetPair2(const _s:string; var _pos:integer; _chleft:char; _chright:char; _escch:boolean=FALSE) : string;
function SQLFormatDouble(const _value:double; const _len,_dec:integer):string;

function IsEmpty(txt:string) : Boolean;





implementation

uses 
  strutils, sysutils;
//  uses
//     SysUtils, strutils;
//     // (Ep) nincs ilyen, lazutf8;


function Lower(const _s:string) : string;
begin
  //   result:=lowercase(_s);   // hibasan konvertalja az ekezeteket
  result := LowerCase(_s);
end;

function Upper(const _s:string) : string;
begin
  //   result:=uppercase(_s);   // hibasan konvertalja az ekezeteket
  result := UpperCase(_s);
end;

procedure SetLen(var _s:string; const _len:integer; const _ch:char);
var
  ws:string;
begin
   if (_len>0) then
   begin
      ws:=StringOfChar(_ch, _len);
      _s:=_s+ws;
      _s:=LeftStr(_s, _len);
   end;
end;

function Space(const _len:integer) : string;
begin
   if (_len>0) then
   begin
      Result:=StringOfChar(' ', _len);
   end;
end;

function LTrim(const _s:string) : string;
begin
   Result:=TrimLeftSet(_s, [' ']);
end;

function RTrim(const _s:string) : string;
begin
   Result:=TrimRightSet(_s, [' ']);
end;

function Alltrim(const _s:string) : string;
begin
   Result:=TrimSet(_s, [' ']);
end;


function Center(const _s:string; const _len:integer) : string;
var
   i,ln,j,b:integer;
begin
   ln:=Length(_s);
   if (ln>_len) then
   begin
      Result:=LeftStr(_s, _len);
   end
   else
   begin
      b:=((_len-ln) div 2);
      j:=_len-b-ln;
      Result:=Space(b);
      Result:=Result+_s;
      for i:=0 to j do
      begin
         Result:=Result+' ';
      end;
   end;
end;




{
  Fuggvenykent adja vissza 's' erteket egy LonInt-ben.
}
function ValI(s : string) : integer;
var i    : integer;
    {%H-}code : integer;        // ez elmulasztja a warning-ot
begin
   Val(AllTrim(s),i,code);
   Result:=i;
end; { Function  Val }

function ValL(s : string) : longint;
var i    : longint;
    {%H-}code : integer;       // ez elmulasztja a warning-ot
begin
   Val(AllTrim(s),i,code);
   Result:=i;
end; { Function  Val }


{
  Fuggvenykent adja vissza 's' erteket egy valosban.
}
function ValD(s : string) : double;
var d    : double;
    {%H-}code : integer;        // ez elmulasztja a warning-ot
begin
   s:=Num(s);
   Val(AllTrim(s),d,code);
   Result:=d;
end; { Function  ValR }

function ValE(s : string) : extended;                     // visszaadja numerikusan
var d    : extended;
    {%H-}code : integer;        // ez elmulasztja a warning-ot
begin
   s:=Num(s);
   Val(AllTrim(s),d,code);
   Result:=d;
end; { Function  ValR }




function StrI(const _i:longint; const _len:integer; const _zeros:boolean=FALSE) : string; overload;
Var
   leading:string;
begin
   Result:=IntToStr(_i);
   if (_zeros=FALSE) then
      leading:=StringOfChar(' ', _len)
   else
      leading:=StringOfChar('0', _len);
   leading:=leading+Result;
   Result:=RightStr(leading, _len);
end;

function StrD(const _e:extended; const _len:integer; const _dec:integer) : string; overload;
var
  ws:string;
begin
   ws:=Space(_len);
   ws:=ws+FloatToStrF(_e, ffFixed, _len, _dec);
   Result:=RightStr(ws,_len);
end;

function StrTrim(const _i:longint; const _len:integer; const _zeros:boolean=FALSE) : string; overload;
begin
   Result:=Trim(StrI(_i, _len, _zeros));
end;


function StrTrim(const _e:extended; const _len:integer; const _dec:integer) : string; overload;
begin
   Result:=AllTrim(StrD(_e, _len, _dec));
end;





function Transform(const _ii   : LongInt; { formazando ertek }
                   const _pict : string   { picture : pl. '999,999' }
                  ) : string; overload;
var i,ri:integer;
     istr:string;
     temp_pict:string;
begin
   temp_pict:=_pict;
   ri:=Length(temp_pict);
   //System.Str(_ii:ri,istr);  - egyes gepeken hibasan mukodik (az utolso jegybol vegtelen tizedes lesz)
   istr:=StrI(_ii, ri, False);
   for i:=ri downto 1 do
   begin
      if (temp_pict[i]='9') then
      begin
          temp_pict[i]:=istr[ri];
          Dec(ri);
      end
(*
      else if (temp_pict[i]=',') then
      begin
           temp_pict[i]:=ThousandSeparator;
      end
      else
      begin
         if (istr[ri] in ['-',' ']) then
         begin
           temp_pict[i]:=istr[ri];
           Dec(ri);
         end;
      end;
*)
      //--else if (istr[ri] in ['-',' ']) then
      else if (CharinSet(istr[ri], ['-',' '])) then
       begin
         temp_pict[i]:=istr[ri];
         Dec(ri);
       end
      else if (temp_pict[i]=',') then
      begin
         temp_pict[i]:=FormatSettings.ThousandSeparator[1];
      end
   end;
   Result:=temp_pict;
end;




{
  Formazott stringge konvertal egy valos szamot.
  NEM figyel alul, vagy felulcsordulast !!!!
}
function Transform(const _r    :extended;   { formazando ertek }
                   const _pict :string      { picture : pl. '999,999.99' }
                  ) : string; overload;
const
   MaxHossz  =30;       { maximalis hossza az eredmeny string-nek }
   MaxTizedes=11;       { tizedesek maximalis szama, amit az STR eljaras
                          letre tud hozni }
var
   i,ri,tp:Byte;
   rs:string;
   temp_pict:string;
begin
   temp_pict:=_pict;
   //System.Str(_r:MaxHossz:MaxTizedes,rs);  - egyes gepeken hibasan mukodik (az utolso jegybol vegtelen tizedes lesz)
   rs:=StrD(_r, MaxHossz, MaxTizedes);
   ri:=MaxHossz-MaxTizedes-1; { tizedespont elott az rs-ben::= 42-20-1 (3.0)-ban !}
   tp:=pos('.',temp_pict);
   if (tp=0) then tp:=Length(temp_pict)+1;
   for i:=Pred(tp) downto 1 do
   begin
      if (temp_pict[i]='9') then
      begin
         temp_pict[i]:=rs[ri];
         Dec(ri);
      end
      else
        //--if (rs[ri] in ['-',' ']) then
        if (CharinSet(rs[ri], ['-',' '])) then
        begin
           temp_pict[i]:=rs[ri];
           Dec(ri);
        end;
   end;

   ri:=MaxHossz-MaxTizedes+1; { tizedespont utan  az rs-ben::= 42-20+1 (3.0)-ban}
   for i:=tp to Length(temp_pict) do
   begin
      if (temp_pict[i]='9') then
      begin
         temp_pict[i]:=rs[ri];
         Inc(ri);
      end;
   end;

   for i:=1 to Length(temp_pict) do
   begin
     if      (temp_pict[i]=',') then temp_pict[i]:=FormatSettings.ThousandSeparator[1]
     else if (temp_pict[i]='.') then temp_pict[i]:=FormatSettings.DecimalSeparator[1];
   end;
   Result:=temp_pict;
end; { Function  TransformR }

function Left(const _s:string; const _len:integer) : string;
begin
   Result:=LeftStr(_s, _len);
end;

function Right(const _s:string; const _len:integer) : string;
begin
   Result:=RightStr(_s, _len);
end;

function Mid(const _s:string; const _pos:integer; const _len:integer) : string;
begin
   Result:=MidStr(_s, _pos, _len);
end;



{
    ha sp==FALSE =>'  9,999.99' => '9999.99'
    ha sp==TRUE  =>'  9,999.99' => '  9999.99'
}
function Num(const _s:string; const _leading_space:boolean=FALSE) : string;
var
   slen, i:integer;
   ws:string;
   ch:char;
   was_valid_char:boolean;
begin
   slen:=Length(_s);
   was_valid_char:=FALSE;
   for i:=1 to slen do
   begin
      ch:=_s[i];
      case ch of
         ' ':begin
               if (_leading_space=TRUE) and (was_valid_char=FALSE) then
                   ws:=ws+ch;
             end;
         '0'..'9','+','-','.':begin
                                ws:=ws+ch;
                                was_valid_char:=TRUE;
                              end;
         else
           if ch=FormatSettings.DecimalSeparator then
           begin
              ws:=ws+'.';
           end;
      end;
   end;
   Result:=ws;
end;

{
  Visszaadja a 'mit' string elso elofordulasat 'miben',
   'pos' poziciotol szamitva.
}
function Match(const _mit:string; const _miben:string; const _pos:integer):integer;
 Var
   sz:string;
   poz:integer;
 Begin
   If (_pos>0) And (_pos<=Length(_miben)) Then
    Begin
      sz:=Copy(_miben, _pos, Length(_miben)-Pred(_pos));
      poz:=pos(_mit,sz);
      If poz>0 Then Result:=_pos+Pred(poz)
      Else Result:=-1;
   End
  Else
      Result:=-1;
 End; { Function  Match }



 // szoveg feldolgozo fv-nyek
procedure GetSpace(const _s:string; var _pos:integer);
const
  TAB=#9;
var
   slen:integer;
   ch:char;
begin
   if (_pos>-1) then   // csak ha ervenyes pozicion van
   begin
      slen:=Length(_s);
      ch:=' ';
      if (_pos<slen) then
         ch:=_s[_pos];
      while (((ch=' ') or (ch=TAB)) and (_pos<slen)) do
      begin
         Inc(_pos);
         ch:=_s[_pos];
      end;
      if ((ch=' ') or (ch=TAB)) and (_pos>slen) then
         _pos:=-1;
   end;
end;

function GetWord(const _s:string; var _pos:integer; const _set:string) : string;
var
   sz:string;
   ch:char;
   slen:integer;
begin
   sz:='';
   if (_pos>-1) then   // csak ha ervenyes pozicion van
   begin
      slen:=Length(_s);
      if (_pos<=slen) then
      begin
         ch:=_s[_pos];
         //--while ((ch in _set) and (_pos<=slen)) do
         while ((System.Pos(ch, _set)>0) and (_pos<=slen)) do
         begin
            sz:=sz+ch;
            Inc(_pos);
            if (_pos<=slen) then
               ch:=_s[_pos];
         end;
         if (_pos>slen) then
            _pos:=-1;
      end
      else
        _pos:=-1;
   end;
   Result:=sz;
end;

procedure Pair(const _s:string; var _pos:integer; _chleft:char; _chright:char);
var
   slen:integer;
   ch:char;
begin
   if (_pos>-1) then   // csak ha ervenyes pozicion van
   begin
      slen:=Length(_s);
      if (slen>0) then
      begin
         ch:=_s[_pos];
         if (ch=_chleft) then
         begin
            Inc(_pos);
            ch:=_s[_pos];
            while (ch<>_chright) and (_pos<slen) do
            begin
               ch:=_s[_pos];
               Inc(_pos);
            end;
            Dec(_pos);   // mert mar tul lepett
         end;
         if (ch=_chright) then
            Inc(_pos);
         if (_pos>=slen) then
            _pos:=-1;
      end;
   end;
end;

function GetPair(const _s:string; var _pos:integer; _chleft:char; _chright:char; _escch:boolean=FALSE): string;
var
   sz:string;
   escvolt:boolean;
   slen:integer;
   ch:char;
   cikl:boolean;

   procedure RAOLVAS;
   begin
     if ((_escch=TRUE) and (ch='\')) then
     begin
        if (_pos<slen-1) then
        begin
           Inc(_pos);
           ch:=_s[_pos];
           if (ch=_chleft) or (ch=_chright) then
           begin
              sz:=sz+ch;
              Inc(_pos);
              ch:=_s[_pos];
           end;
           escvolt:=TRUE;
        end;
     end;
   end;

begin
   sz:='';
   escvolt:=FALSE;
   if (_pos>-1) then   // csak ha ervenyes pozicion van
   begin
      slen:=Length(_s);
      if (slen>0) then
      begin
         ch:=_s[_pos];  RAOLVAS();

         if (ch=_chleft) then
         begin
            if (_pos<slen-1) then
            begin
               Inc(_pos);
               ch:=_s[_pos];   RAOLVAS;
            end;
            cikl:=(ch<>_chright) and (_pos<slen);
            if (_pos>0) then
            begin
               if ((ch=_chright) and (_s[_pos-1]=ch)) then
                  Inc(_pos);
            end;
            if (escvolt=TRUE) then
               cikl:=TRUE;
            while (cikl=TRUE) do
            begin
               escvolt:=FALSE;
               if (_pos<slen) then
               begin
                  ch:=_s[_pos];
                  RAOLVAS;
                  if (ch<>_chright) then
                     sz:=sz+ch;
               end;

               cikl:=(ch<>_chright) and (_pos<slen);
               if (escvolt=TRUE) then
                  cikl:=TRUE;
               Inc(_pos);
            end;
            Dec(_pos);   // mert mar tul lepett
            if (ch=_chright) then
               LeftStr(sz, Length(sz)-1);
         end;
         if (ch=_chright) then
            Inc(_pos);
         if (_pos>=slen) then
            _pos:=-1;
      end;
   end;
   Result:=sz;
end;
function GetPair2(const _s:string; var _pos:integer; _chleft:char; _chright:char; _escch:boolean=FALSE) : string;
var
   sz:string;
   escvolt:boolean;
   slen:integer;
   ch:char;
   cikl:boolean;
   count:integer;

   procedure RAOLVAS;
   begin
     if ((_escch=TRUE) and (ch='\')) then
     begin
        if (_pos<slen-1) then
        begin
           Inc(_pos);
           ch:=_s[_pos];
           escvolt:=TRUE;
        end;
     end;
   end;

begin
   sz:='';
   count:=0;
   escvolt:=FALSE;
   if (_pos>-1) then   // csak ha ervenyes pozicion van
   begin
      slen:=Length(_s);
      if (slen>0) then
      begin
         ch:=_s[_pos];  RAOLVAS();

         if (ch=_chleft) then
         begin
            Inc(count);
            if (_pos<slen-1) then
            begin
               Inc(_pos);
               ch:=_s[_pos];
               if (ch=_chright) then
                  Dec(count);
               RAOLVAS;
            end;
            cikl:=(count>0) and (_pos<slen);
            if (_pos>0) then
            begin
               if ((ch=_chright) and (_s[_pos-1]=ch)) then
                  Inc(_pos);
            end;
            if (escvolt=TRUE) then
               cikl:=TRUE;
            while (cikl=TRUE) do
            begin
               escvolt:=FALSE;
               if (_pos<slen) then
               begin
                  ch:=_s[_pos];
                  if (ch=_chleft) then
                     Inc(count);
                  if (ch=_chright) then
                     Dec(count);
                  RAOLVAS;
                  sz:=sz+ch;
               end;

               cikl:=(count>0) and (_pos<slen);
               if (escvolt=TRUE) then
                  cikl:=TRUE;
               Inc(_pos);
            end;
            Dec(_pos);   // mert mar tul lepett
            if (ch=_chright) then
               LeftStr(sz, Length(sz)-1);
         end;
         if (ch=_chright) then
            Inc(_pos);
         if (_pos>=slen) then
            _pos:=-1;
      end;
   end;
   Result:=sz;
end;

function SQLFormatDouble(const _value:double; const _len,_dec:integer):string;
var
  wds : char;

begin
   wds := FormatSettings.DecimalSeparator[1];
   FormatSettings.DecimalSeparator := '.';
   Result:=FloatToStrF(_value, ffFixed, _len, _dec);
   FormatSettings.DecimalSeparator := wds;
end;


function IsEmpty(txt:string) : Boolean;

begin

   Result:= (Length(AllTrim(txt)) = 0);

end;


end.

