unit Talalkozasok;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Menus, WEBLib.StdCtrls, web_eo_memo, WEBLib.ComCtrls, web_eo_string;

type
  TTalalkozasokForm = class(TForm)
    Memo1: TMemo;
    WE_STRING1: TWE_STRING;
    WebEdit1: TEdit;
    procedure TalalkozasokFormCreate(Sender: TObject);
    procedure TalalkozasokFormDestroy(Sender: TObject);
    procedure TalalkozasokFormShow(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  TalalkozasokForm: TTalalkozasokForm;

implementation

{$R *.dfm}

procedure TTalalkozasokForm.TalalkozasokFormShow(Sender: TObject);
begin
  Memo1.Text := 'Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio ';
//  Memo1.Lines.Add('Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio Alma bela korte dio ');
end;

procedure TTalalkozasokForm.TalalkozasokFormDestroy(Sender: TObject);
begin
  //
end;

procedure TTalalkozasokForm.TalalkozasokFormCreate(Sender: TObject);
begin
  //
end;

procedure TTalalkozasokForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Memo1 := TMemo.Create('emTalalkozasok');
  WE_STRING1 := TWE_STRING.Create('esUtcak');
  WebEdit1 := TEdit.Create('esUgyfelek');

  Memo1.BeforeLoadDFMValues;
  WE_STRING1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  try
    Name := 'TalalkozasokForm';
    CSSLibrary := cssBootstrap;
    Color := clWhite;
    ElementFont := efCSS;
    Font.Charset := ANSI_CHARSET;
    Font.Color := clBlack;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Size := 8;
    Font.Style := [];
    FormStyle := fsNormal;
    Height := 642;
    Left := 0;
    SetEvent(Self, 'OnCreate', 'TalalkozasokFormCreate');
    SetEvent(Self, 'OnDestroy', 'TalalkozasokFormDestroy');
    SetEvent(Self, 'OnShow', 'TalalkozasokFormShow');
    TabOrder := 0;
    Top := 0;
    Width := 1058;
    Memo1.SetParentComponent(Self);
    Memo1.Name := 'Memo1';
    Memo1.AutoSize := False;
    Memo1.Color := clWindow;
    Memo1.ElementPosition := epRelative;
    Memo1.Font.Charset := ANSI_CHARSET;
    Memo1.Font.Color := clBlack;
    Memo1.Font.Height := -11;
    Memo1.Font.Name := 'Arial';
    Memo1.Font.Size := 8;
    Memo1.Font.Style := [];
    Memo1.Height := 300;
    Memo1.Left := 561;
    Memo1.ReadOnly := False;
    Memo1.Role := 'null';
    Memo1.SelLength := 0;
    Memo1.SelStart := 0;
    Memo1.ShowFocus := True;
    Memo1.TabOrder := 0;
    Memo1.Top := 265;
    Memo1.Width := 400;
    WE_STRING1.SetParentComponent(Self);
    WE_STRING1.Name := 'WE_STRING1';
    WE_STRING1.Alignment := taLeftJustify;
    WE_STRING1.Color := clWindow;
    WE_STRING1.ElementFont := efCSS;
    WE_STRING1.ElementPosition := epRelative;
    WE_STRING1.Enabled := True;
    WE_STRING1.Font.Charset := ANSI_CHARSET;
    WE_STRING1.Font.Color := clBlack;
    WE_STRING1.Font.Height := -11;
    WE_STRING1.Font.Name := 'Arial';
    WE_STRING1.Font.Size := 8;
    WE_STRING1.Font.Style := [];
    WE_STRING1.HandleEsc := True;
    WE_STRING1.Height := 25;
    WE_STRING1.HideSelection := False;
    WE_STRING1.IsValid := True;
    WE_STRING1.Left := 247;
    WE_STRING1.LookIsModified := True;
    WE_STRING1.MaxLength := 0;
    WE_STRING1.ObjectID := 0;
    WE_STRING1.Permission := 0;
    WE_STRING1.ReadOnly := False;
    WE_STRING1.ShowFocus := True;
    WE_STRING1.StopWhenError := False;
    WE_STRING1.TabOrder := 0;
    WE_STRING1.Top := 72;
    WE_STRING1.Visible := True;
    WE_STRING1.Width := 100;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Alignment := taLeftJustify;
    WebEdit1.Color := clWindow;
    WebEdit1.ElementFont := efCSS;
    WebEdit1.ElementPosition := epRelative;
    WebEdit1.Font.Charset := ANSI_CHARSET;
    WebEdit1.Font.Color := clBlack;
    WebEdit1.Font.Height := -11;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Size := 8;
    WebEdit1.Font.Style := [];
    WebEdit1.Height := 25;
    WebEdit1.HideSelection := False;
    WebEdit1.Left := 736;
    WebEdit1.ShowFocus := True;
    WebEdit1.TabOrder := 0;
    WebEdit1.Top := 117;
    WebEdit1.Width := 100;
  finally
    Memo1.AfterLoadDFMValues;
    WE_STRING1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
  end;
end;

end.                   