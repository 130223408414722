unit web_permission_helper_u;

interface

uses
   Classes, SysUtils, Controls;

type
  TPermissionHelper = class
  public
     class procedure SetPermission(obj:TWinControl; value:Integer); static;
     class function  GetPermission(obj:TWinControl):Integer; static;

     class function  IsVisible(perm:Integer):Boolean; static;
     class function  IsEnabled(perm:Integer):Boolean; static;
     class function  IsReadOnly(perm:Integer):Boolean; static;

     class procedure SetVisible(obj:TWinControl; value:Boolean); static;  overload;
     class procedure SetEnabled(obj:TWinControl; value:Boolean); static;  overload;
     class procedure SetReadOnly(obj:TWinControl; value:Boolean); static;  overload;

     class procedure SetVisible(var perm:Integer; value:Boolean); static;  overload;
     class procedure SetEnabled(var perm:Integer; value:Boolean); static;  overload;
     class procedure SetReadOnly(var perm:Integer; value:Boolean); static;  overload;
  end;

implementation

uses
   web_eo_common,
   web_es_system,
   web_eo_iif
   ;

const
   VISIBLE_BIT :Integer = 3;
   ENABLED_BIT :Integer = 2;
   READONLY_BIT:Integer = 1;

class procedure TPermissionHelper.SetPermission(obj:TWinControl; value:Integer);
begin
   if (obj is  E_INTERFACE) then
   begin
      if (value > Integer(permDEFAULT)) then
      begin
        obj.Visible := IIFF<Boolean>.Value(IsVisible(value), True, False);
        obj.Enabled := IIFF<Boolean>.Value(IsEnabled(value), True, False);
        if (IsReadOnly(value)) then
        begin
           (obj as E_INTERFACE).setReadOnly(True);
        end
        else
        begin
          (obj as E_INTERFACE).setReadOnly(False);
        end;
      end;
   end;
end;


class function  TPermissionHelper.GetPermission(obj:TWinControl):Integer;
begin
   //Result:=Integer(permDEFAULT);
   Result:=Ord(permDEFAULT);
   if (obj is  E_INTERFACE) then
   begin
      Result:=(obj as E_INTERFACE).Permission;
   end;
end;


class function  TPermissionHelper.IsVisible(perm:Integer):Boolean;
begin
   Result:=(perm=0) or TestBit(perm, VISIBLE_BIT);
end;

class function  TPermissionHelper.IsEnabled(perm:Integer):Boolean;
begin
   Result:=(perm=0) or TestBit(perm, 2);
end;

class function  TPermissionHelper.IsReadOnly(perm:Integer):Boolean;
begin
   if (perm=0) then
   begin
      Result:=false;
   end
   else
   begin
     Result:=TestBit(perm, 1);
   end;
end;


class procedure TPermissionHelper.SetVisible(obj:TWinControl; value:Boolean);
var
  intf:E_INTERFACE;
  wi:Integer;
begin
   if (obj is  E_INTERFACE) then
   begin
      intf:=(obj as E_INTERFACE);
      wi:=intf.Permission;;
      if (value) then
      begin
         SetBit(wi, VISIBLE_BIT);
      end
      else
      begin
         ClearBit(wi, VISIBLE_BIT);
      end;
      intf.Permission:=wi;
   end;
end;

class procedure TPermissionHelper.SetEnabled(obj:TWinControl; value:Boolean);
var
  intf:E_INTERFACE;
  wi:Integer;
begin
   if (obj is  E_INTERFACE) then
   begin
      intf:=(obj as E_INTERFACE);
      wi:=intf.Permission;;
      if (value) then
      begin
         SetBit(wi, ENABLED_BIT);
      end
      else
      begin
         ClearBit(wi, ENABLED_BIT);
      end;
      intf.Permission:=wi;
   end;
end;

class procedure TPermissionHelper.SetReadOnly(obj:TWinControl; value:Boolean);
var
  intf:E_INTERFACE;
  wi:Integer;
begin
   if (obj is  E_INTERFACE) then
   begin
      intf:=(obj as E_INTERFACE);
      wi:=intf.Permission;;
      if (value) then
      begin
         SetBit(wi, READONLY_BIT);
      end
      else
      begin
         ClearBit(wi, READONLY_BIT);
      end;
      intf.Permission:=wi;
   end;
end;


class procedure TPermissionHelper.SetVisible(var perm:Integer; value:Boolean);
begin
   SetBit(perm, VISIBLE_BIT);
end;

class procedure TPermissionHelper.SetEnabled(var perm:Integer; value:Boolean);
begin
   SetBit(perm, ENABLED_BIT);
end;

class procedure TPermissionHelper.SetReadOnly(var perm:Integer; value:Boolean);
begin
   SetBit(perm, READONLY_BIT);
end;



end.


